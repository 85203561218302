import {
  CourseParticipant,
} from "../../types";
import { createContext } from "react";

const courseParticipants: CourseParticipant[] = [
  {
    firstName: "Mario",
    lastName: "Rossi",
    headquarter: "Genova",
    jobDescription: "Operaio",
  },
  {
    firstName: "Bianco",
    lastName: "Steven",
    headquarter: "Genova",
    jobDescription: "Impiegato Tecnico",
  },
  {
    firstName: "Verde",
    lastName: "Steven",
    headquarter: "Genova",
    jobDescription: "Impiegato Tecnico",
  },
  {
    firstName: "Blu",
    lastName: "Steven",
    headquarter: "Genova",
    jobDescription: "Impiegato Tecnico",
  },
  {
    firstName: "Giallo",
    lastName: "Steven",
    headquarter: "Genova",
    jobDescription: "Impiegato Tecnico",
  },
  {
    firstName: "Viola",
    lastName: "Steven",
    headquarter: "Genova",
    jobDescription: "Impiegato Tecnico",
  },
  {
    firstName: "Grigio",
    lastName: "Steven",
    headquarter: "Genova",
    jobDescription: "Impiegato Tecnico",
  },
];

/*
status-expire: 1 valido, 2 in scadenza, 3 scaduto
status: 1 in approvazione, 2 prenotato, 3 completato
*/

export const CourseCertificates = [
  {
    course: "PRIMO SOCCORSO GRUPPI B-C",
    certificates: [
      {
        course: "PRIMO SOCCORSO GRUPPI B-C",
        id: 1,
        name: "Rossi Mario",
        headquarter: "Genova",
        hours: 4,
        date_exp: "11/05/2024",
        status_expire: 3,
        status: 3,
      },
      {
        course: "PRIMO SOCCORSO GRUPPI B-C",
        id: 2,
        name: "Steven Bianco",
        headquarter: "Genova",
        hours: 4,
        date_exp: "11/08/2025",
        status_expire: 1,
        status: 3,
      },
      {
        course: "PRIMO SOCCORSO GRUPPI B-C",
        id: 3,
        name: "Steven Verde",
        headquarter: "Genova",
        hours: 4,
        date_exp: "20/10/2024",
        status_expire: 2,
        status: 2,
        date_renew: "20/12/2024",
      },
    ],
  },
  {
    course: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
    certificates: [
      {
        course: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
        id: 4,
        name: "Steven Blu",
        headquarter: "Genova",
        hours: 8,
        date_exp: "11/08/2025",
        status_expire: 1,
        status: 3,
      },
      {
        course: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
        id: 5,
        name: "Steven Giallo",
        headquarter: "Savona",
        hours: 4,
        date_exp: "11/08/2025",
        status_expire: 1,
        status: 3,
      },
      {
        course: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
        id: 6,
        name: "Steven Viola",
        headquarter: "Genova",
        hours: 4,
        date_exp: "20/01/2025",
        status_expire: 2,
        status: 2,
        date_renew: "15/02/2025",
      },
    ],
  },
];

export const CourseEmployees = [
  {
    name: "Rossi Mario",
    cf: "MRORSS80C06F205Q",
    headquarter: "GENOVA",
    status_expire: 3,
    date_exp: "11/05/2024",
    certificates: [
      {
        id: 1,
        name: "PRIMO SOCCORSO GRUPPI B-C",
        description: "Livello 1",
        date_exp: "11/05/2024",
        status_expire: 3,
        status: 3,
      },
      {
        id: 2,
        name: "Antincendio",
        description: "Generale",
        date_exp: "11/02/2025",
        status_expire: 1,
        status: 3,
      },
      {
        id: 3,
        name: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
        description: "Specifica",
        date_exp: "01/12/2024",
        status_expire: 2,
        status: 2,
        date_renew: "12/12/2024",
      },
    ],
  },
  {
    name: "Steven Giallo",
    cf: "STVGLL90R02F205Y",
    headquarter: "IMPERIA",
    status_expire: 1,
    date_exp: "08/11/2025",
    certificates: [
      {
        id: 4,
        name: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
        description: "Livello 1",
        date_exp: "08/11/2025",
        status_expire: 1,
        status: 3,
      },
    ],
  },
  {
    name: "Steven Verde",
    cf: "STVVRD90R02F205Y",
    headquarter: "SAVONA",
    status_expire: 3,
    date_exp: "08/06/2023",
    certificates: [
      {
        id: 5,
        name: "PRIMO SOCCORSO GRUPPI B-C",
        description: "Livello 1",
        date_exp: "08/06/2023",
        status_expire: 3,
        status: 3,
      },
      {
        id: 6,
        name: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
        description: "Livello 1",
        date_exp: "10/10/2024",
        status_expire: 2,
        status: 2,
        date_renew: "05/11/2024",
      },
    ],
  },
];

export const Certificates = [
  {
    id: 1,
    name: "PRIMO SOCCORSO GRUPPI B-C",
    discente: "Rossi Mario",
    headquarter: "GENOVA",
    status_expire: 3,
    date_exp: "11/05/2024",
  },
  {
    id: 2,
    name: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
    discente: "Steven Blu",
    headquarter: "IMPERIA",
    date_exp: "11/02/2025",
    status_expire: 1,
    status: 3,
  },
  {
    id: 3,
    name: "Antincendio",
    discente: "Steven Giallo",
    headquarter: "SAVONA",
    status_expire: 2,
    date_exp: "23/11/2024",
    status: 2,
    date_renew: "10/01/2025",
  },
  {
    id: 4,
    name: "PRIMO SOCCORSO GRUPPI B-C",
    discente: "Steven Viola",
    headquarter: "Genova",
    status_expire: 1,
    date_exp: "23/04/2025",
    status: 3,
  },
];

export const participantsContext = createContext(courseParticipants);
export const selectedParticipant = createContext(new Set<string>());

/*export const coursesArray: CourseCarouselItem[] = [
  {
    date: new Date(2024, 8, 30),
    courseName: "aggiornamento addetti antincendio livello 3 (3 agg)",
    mode: 1,
    city: "genova",
    address: "polo struppa via gualco 58 (ge)",
    id: 0,
    courseId: '0',
    start: new Date(),
    end: new Date(),
    variationId: 0,
    carousel: false
  },
  {
    date: new Date(2024, 9, 30),
    courseName: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
    mode: 1,
    city: "genova",
    address: "area ple Bolzaneto via san quirico 33R (Ge)",
    id: 0,
    courseId: '0',
    start: new Date(),
    end: new Date(),
    variationId: 1,
    carousel: false
  },
  {
    date: new Date(2024, 8, 24),
    courseName: "primo soccorso gruppi B-C",
    mode: 1,
    city: "genova",
    address: "Aula Giano via al molo giano (GE)",
    id: 0,
    courseId: '0',
    start: new Date(),
    end: new Date(),
    variationId: 2,
    carousel: false
  },
  {
    date: new Date(2024, 10, 7),
    courseName: "RSPP DATORE DI LAVORO RISCHIO BASSO",
    mode: 1,
    city: "genova",
    address: "polo struppa via gualco 58 (ge)",
    id: 0,
    courseId: '0',
    start: new Date(),
    end: new Date(),
    variationId: 3,
    carousel: false
  },
  {
    date: new Date(2024, 11, 6),
    courseName: "SPAZI CONFINATI - 12 ORE",
    mode: 1,
    city: "genova",
    address: "polo struppa via gualco 58 (ge)",
    id: 0,
    courseId: '0',
    start: new Date(),
    end: new Date(),
    variationId: 4,
    carousel: false
  },
];*/


/*const mockCourse: CoursePage[] = [
  {
    name: "aggiornamento addetti antincendio livello 3 (3 agg)",
    availableDates: [
      {
        day: 2,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Via al molo giano genova ge 16128",
        duration: 8,
        detail: {
          firstDate: "2 sett",
          secondDate: "3 sett",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 30,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Via al molo giano genova ge 16128",
        duration: 8,
        detail: {
          firstDate: "30 sett",
          secondDate: "1 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 17,
        month: 10,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Via al molo giano genova ge 16128",
        duration: 8,
        detail: {
          firstDate: "17 ott",
          secondDate: "18 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 20,
        month: 11,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Via al molo giano genova ge 16128",
        duration: 8,
        detail: {
          firstDate: "20 nov",
          secondDate: "21 nov",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 9,
        month: 12,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Via al molo giano genova ge 16128",
        duration: 8,
        detail: {
          firstDate: "9 dic",
          secondDate: "10 dic",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
    ],
  },
  {
    name: "AGGIORNAMENTO PLE (PIATTAFORME AEREE)",
    availableDates: [
      {
        day: 2,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "area ple Bolzaneto via san quirico 33R (Ge)",
        duration: 8,
        detail: {
          firstDate: "2 sett",
          secondDate: "3 sett",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 30,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "area ple Bolzaneto via san quirico 33R (Ge)",
        duration: 8,
        detail: {
          firstDate: "30 sett",
          secondDate: "1 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 16,
        month: 10,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "area ple Bolzaneto via san quirico 33R (Ge)",
        duration: 8,
        detail: {
          firstDate: "16 ott",
          secondDate: "17 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 20,
        month: 11,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "area ple Bolzaneto via san quirico 33R (Ge)",
        duration: 8,
        detail: {
          firstDate: "20 nov",
          secondDate: "21 nov",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 9,
        month: 12,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "area ple Bolzaneto via san quirico 33R (Ge)",
        duration: 8,
        detail: {
          firstDate: "9 dic",
          secondDate: "10 dic",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
    ],
  },
  {
    name: "primo soccorso gruppi B-C",
    availableDates: [
      {
        day: 24,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Aula Giano via al molo giano (GE)",
        duration: 8,
        detail: {
          firstDate: "24 sett",
          secondDate: "25 sett",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 30,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Aula Giano via al molo giano (GE)",
        duration: 8,
        detail: {
          firstDate: "30 sett",
          secondDate: "1 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 16,
        month: 10,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Aula Giano via al molo giano (GE)",
        duration: 8,
        detail: {
          firstDate: "16 ott",
          secondDate: "17 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 20,
        month: 11,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Aula Giano via al molo giano (GE)",
        duration: 8,
        detail: {
          firstDate: "20 nov",
          secondDate: "21 nov",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 9,
        month: 12,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "Aula Giano via al molo giano (GE)",
        duration: 8,
        detail: {
          firstDate: "9 dic",
          secondDate: "10 dic",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
    ],
  },
  {
    name: "RSPP DATORE DI LAVORO RISCHIO BASSO",
    availableDates: [
      {
        day: 24,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "24 sett",
          secondDate: "25 sett",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 30,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "30 sett",
          secondDate: "1 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 16,
        month: 10,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "16 ott",
          secondDate: "17 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 7,
        month: 11,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "7 nov",
          secondDate: "8 nov",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 9,
        month: 12,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "9 dic",
          secondDate: "10 dic",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
    ],
  },
  {
    name: "SPAZI CONFINATI - 12 ORE",
    availableDates: [
      {
        day: 24,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "24 sett",
          secondDate: "25 sett",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 30,
        month: 9,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "30 sett",
          secondDate: "1 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 16,
        month: 10,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "16 ott",
          secondDate: "17 ott",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 7,
        month: 11,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "7 nov",
          secondDate: "8 nov",
          firstHour: "14:00-18:00",
          secondHour: "09:00-13:00",
        },
      },
      {
        day: 6,
        month: 12,
        mode: "In presenza",
        classroom: "Aula Good work",
        city: "Genova",
        address: "polo struppa via gualco 58 (ge)",
        duration: 8,
        detail: {
          firstDate: "6 dic",
          secondDate: "6 dic",
          firstHour: "09:00-13:00",
          secondHour: "14:00-18:00",
        },
      },
    ],
  },
];*/

//export const coursesPage = createContext(mockCourse);
