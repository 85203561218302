import styled from "styled-components";
//import {useTranslation} from "react-i18next";
import {Employee} from "../../../../types";
import {Color} from "../../../../constants/color";
import Label from "../../../label";
import {useState} from "react";

interface Props {
    participant: Employee,
    onAdd: (name: string) => void,
    index: number,
    checked: boolean
}

const SingleParticipantsWrapper = styled.div`
    display: flex;
    align-items: center;
    min-width: 50rem;
    min-height: 2.6rem;
    background-color: ${Color.LIGHT_GREY};
    border-style: solid;
    border-width: 1px;
    border-color: ${Color.GREY};
    border-radius: 0.8rem;
    margin-right: 1rem;
    
`;

const LabelContainer = styled.div`
    display: flex;
    flex-grow: 400;
    justify-content: start;
    padding-left: 4rem;
`;

const SingleParticipant = ( { participant, onAdd, checked } : Props ) => {
    //const { t } = useTranslation();
    const [s, setS] = useState(checked)
    const handleSelected = () => onAdd && onAdd(participant.cf);
    return (
        <SingleParticipantsWrapper>
            <LabelContainer>
                <Label text={participant.lastName + ' ' + participant.firstName}
                       color={Color.DARK_GREY}
                       textAlign={'left'}
                       textTransform={'uppercase'}
                       fontWeight={'normal'}
                       fontSize={'14px'}
                       width={'30%'}
                />
                <Label text={participant.workplace}
                       color={Color.DARK_GREY}
                       textTransform={'uppercase'}
                       fontWeight={'normal'}
                       fontSize={'14px'}
                       textAlign={'center'}
                       width={'30%'}
                />
                <Label text={participant.jobDescription}
                       color={Color.DARK_GREY}
                       textTransform={'uppercase'}
                       fontWeight={'normal'}
                       fontSize={'14px'}
                       textAlign={'center'}
                       width={'30%'}
                />
                <input type={'checkbox'}
                       onChange={ () => {
                           handleSelected();
                           setS(!s);
                       }}
                       checked={s}
                       value={participant.cf}
                />
            </LabelContainer>
        </SingleParticipantsWrapper>
    );
};

export default SingleParticipant