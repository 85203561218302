import styled from "styled-components";
import {CourseDate, Employee} from "../../../../../types";
import {Color} from "../../../../../constants/color";
import {Icons} from "../../../../../constants/icons";
import Label from "../../../../label";
import Button from "../../../../button/button";
import {useEffect, useState} from "react";
import BookModal from "../../../../modal/book-course";
import {dateOptionsShort, getCourseMode, getDateDuration, getLocale} from "../../../../../utils/utils";
import {getEmployeeByCompanyId} from "../../../../../features/employee/utils";
import {useTranslation} from "react-i18next";

interface Props {
    show: boolean,
    date: CourseDate[]
}

const DetailWrapper = styled.div.attrs({
    className: 'dateDetail',
}) <{
    $show: boolean
}>`
    display: ${props => props.$show ? 'flex' : 'none'};
    flex-direction: column;
    //position: absolute;
    justify-content: center;
    //padding-left: 2rem;
    //padding-right: 2rem;
    row-gap: 4rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
    box-shadow: 0 0 20px 10px ${Color.SHADOW};
    background-color: ${Color.WHITE};
    border-radius: 24px;
    min-height: 14rem;
    width: 890px;
`;

const Row1 = styled.div`
    display: flex;
    max-height: 2rem;
    column-gap: 20px;
    justify-content: start;
    max-width: 40rem;
    margin-left: 40px;
`;

const Row2 = styled.div`
    display: flex;
    max-height: 2rem;
    column-gap: 20px;
    justify-content: start;
    max-width: 50rem;
    align-items: center;
    margin-left: 40px;
`;

const ButtonContainer = styled.div`
    display: flex;
    position: relative;
    left: 160px;
    //padding-left: 9rem;
`;

const DetailColumn = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.1rem;
    width: 6.6rem;
    div {
        text-align: left;
        max-width: 16rem;
        width: 260px;
    }
`;

const SubRow = styled.div`
    display: flex;
    align-items: center;
    width: 235px;
`;

const Icon = styled.img`
    width: 1.6rem;
`;

const DateDetail = ( {show, date} : Props ) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false)
    const duration = getDateDuration(date);
    const date1 = date[0]
    const date2 = date[1] ?? null
    const day1 : Date = new Date(date1.date);
    const day2 : Date | null = date2 !== null ? new Date(date2.date) : null;
    const formattedDate1 = day1.toLocaleDateString(getLocale(), dateOptionsShort)
    const formattedDate2 = day2 !== null ? day2.toLocaleDateString(getLocale(), dateOptionsShort) : ''

    const [employeeList, setEmployeeList] = useState<Employee[]>()

    useEffect(() => {
        getEmployeeByCompanyId()!!.then(response => setEmployeeList(response))
    }, []);


    return (
        <DetailWrapper $show={show}>
            <Row1>
                <Icon alt={'data'} src={Icons.COMMONS.CALENDARGREEN}/>
                <DetailColumn>
                    <Label text={t('course-mode-date')}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'10px'}
                           textAlign={'center'}
                           color={Color.DARK_GREY}
                    />
                    <Label text={formattedDate1 + (formattedDate2 !== '' ? ' | ' + formattedDate2 : '')}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'13px'}
                           textAlign={'center'}
                           color={Color.LIGHT_BLUE}
                           width={'116px'}
                    />
                </DetailColumn>
                <Icon alt={'duration'} src={Icons.COMMONS.CLOCK}/>
                <DetailColumn>
                    <Label text={t('course-mode-duration')}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'10px'}
                           textAlign={'center'}
                           color={Color.DARK_GREY}
                           width={'max-content'}
                    />
                    <Label text={duration + ' ' + t('hours')}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'13px'}
                           textAlign={'center'}
                           color={Color.LIGHT_BLUE}
                    />
                </DetailColumn>
                <Icon alt={'schedule'} src={Icons.COMMONS.CLOCKALERT}/>
                <SubRow>
                    <DetailColumn>
                        <Label text={t('course-mode-first-day')}
                               textTransform={'uppercase'}
                               fontWeight={'bold'}
                               fontSize={'10px'}
                               textAlign={'center'}
                               color={Color.DARK_GREY}
                               width={'6rem'}
                        />
                        <Label text={date1.start.toString().slice(0, 5) + ' | ' + date1.end.toString().slice(0, 5)}
                               textTransform={'uppercase'}
                               fontWeight={'bold'}
                               fontSize={'13px'}
                               textAlign={'center'}
                               color={Color.LIGHT_BLUE}
                        />
                    </DetailColumn>
                    {date2 !== null && <Label text={'|'}
                            textTransform={'uppercase'}
                            fontWeight={'normal'}
                            fontSize={'30px'}
                            textAlign={'left'}
                            color={Color.LIGHT_BLUE}
                            width={'-webkit-fill-available'}
                            height={'40px'}
                    />}
                    <DetailColumn>
                        {date2 !== null && <Label text={t('course-mode-second-day')}
                                textTransform={'uppercase'}
                                fontWeight={'bold'}
                                fontSize={'10px'}
                                textAlign={'center'}
                                color={Color.DARK_GREY}
                                width={'8rem'}
                        />}
                        {date2 !== null && <Label text={date2.start.toString().slice(0, 5) + ' | ' + date2.end.toString().slice(0, 5)}
                                textTransform={'uppercase'}
                                fontWeight={'bold'}
                                fontSize={'13px'}
                                textAlign={'center'}
                                color={Color.LIGHT_BLUE}
                        />}
                    </DetailColumn>
                </SubRow>
            </Row1>
            <Row2>
                <Icon alt={'modalita'} src={Icons.COMMONS.NOTEBOOKGREEN}/>
                <DetailColumn>
                    <Label text={t('book-course-lecture-mode')}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'10px'}
                           textAlign={'center'}
                           color={Color.DARK_GREY}
                    />
                    <Label text={t(getCourseMode(date1.mode))}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'13px'}
                           textAlign={'center'}
                           color={Color.LIGHT_BLUE}
                    />
                </DetailColumn>
                <Icon alt={'citta'} src={Icons.COMMONS.MAPPINGREEN}/>
                <DetailColumn>
                    <Label text={t('book-course-city')}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'10px'}
                           textAlign={'center'}
                           color={Color.DARK_GREY}

                    />
                    <Label text={date1.city}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'13px'}
                           textAlign={'center'}
                           color={Color.LIGHT_BLUE}
                    />
                </DetailColumn>
                <Icon alt={'sede'} src={Icons.COMMONS.BUILDINGGREEN}/>
                <DetailColumn>
                    <Label text={t('book-course-headquarter')}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'10px'}
                           textAlign={'center'}
                           color={Color.DARK_GREY}
                    />
                    <Label text={date1.address}
                           textTransform={'uppercase'}
                           fontWeight={'bold'}
                           fontSize={'13px'}
                           textAlign={'center'}
                           color={Color.LIGHT_BLUE}
                           width={'10rem'}
                    />
                </DetailColumn>
                <ButtonContainer>
                    <Button
                        label={t('book-course-reserve-button')}
                        color={Color.BLUE}
                        icon={Icons.COMMONS.CALENDAR}
                        onClick={ () => setShowModal(true)}
                    />
                </ButtonContainer>
            </Row2>
            { employeeList && <BookModal participants={employeeList} show={showModal} setShow={() => setShowModal(false)} variationId={date1.variationId}/>}
        </DetailWrapper>
    )
}

export default DateDetail