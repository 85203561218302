import { useTranslation } from "react-i18next";
import {Employee} from "../../../types";
import CustomSearch from "../../search";
import { Color } from "../../../constants/color";
import SingleParticipant from "./single-participant";
import Label from "../../label";
import Button from "../../button/button";
import { Icons } from "../../../constants/icons";
import {useEffect, useState} from "react";
import styled from "styled-components";
import {getReserveCourseDateByCompanyId, reserveCourseDate} from "../../../features/reservation/utils";
import {useParams} from "react-router-dom";

interface Props {
  participants: Employee[];
  show: boolean;
  setShow: () => void;
  variationId: number;
}

const ParticipantsWrapper = styled.div<{
  $open: boolean;
}>`
  display: ${($open) => ($open.$open ? "flex" : "none")};
  transition: display 2s;
  flex-direction: column;
  align-items: center;
  row-gap: 0.8rem;
  height: 33.2rem;
  width: max-content;
  background-color: ${Color.CERAMIC};
  box-shadow: 0 0 32px -8px ${Color.LIGHT_BLUE};
  border-radius: 1.2rem;
  padding-bottom: 1.6rem;
  position: absolute;
  margin: auto auto 190px;
  z-index: 2;

  ::-webkit-scrollbar {
    position: relative;
    display: flex;
    left: 4rem;
    width: 0.6rem;
    border-radius: 4rem;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-track {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }

  ::-webkit-scrollbar-track-piece {
    border-radius: 4rem;
    background-color: ${Color.GREY};
  }
`;

const ModalHeader = styled.div`
  background-color: ${Color.LIGHT_BLUE};
  width: 100%;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const SearchContainer = styled.div`
  display: flex;
  column-gap: 2rem;
  margin-top: 1rem;
`;

const SearchColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.4rem;
  margin-left: 2.2rem;
  margin-right: auto;
  width: 50rem;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  min-width: max-content;
  overflow-x: visible;
  overflow-y: auto;
  height: 22rem;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const EditButtonsContainer = styled.div`
  display: flex;
  position: relative;
  left: 16rem;
  justify-content: right;
  column-gap: 2rem;
`;

const BookModal = ({ show, setShow, participants, variationId }: Props) => {
  const { t } = useTranslation();
  let { selectedEmployees } = useParams();
  const [participantList, setList] = useState(participants);
  const [participantsSelected, setParticipantsSelected] = useState<Set<string>>(new Set());
  const [selected] = useState<Set<string>>(participantsSelected);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    getReserveCourseDateByCompanyId(variationId)!!.then(response => {
      setParticipantsSelected(response)
      setLoading(false)
    })
  }, [variationId]);

  participantsSelected.forEach(el => selected.add(el.replace(/'/g, '').trim()))
  if (selectedEmployees) selectedEmployees.split(',').forEach(el => selected.add(el.replace(/'/g, '').trim().toUpperCase()))


  const handleSelected = (cf: string) => {
    if (selected.has(cf)) {
      selected.delete(cf);
      return;
    }
    selected.add(cf);
  };
  function searchParticipant(
    participants: Employee[],
    input: string,
    type: number
  ) {
    if (type === 1) {
      setList(
        participants.filter((el) =>
          (
            el.lastName.toLowerCase() +
            " " +
            el.firstName.toLowerCase()
          ).includes(input)
            ? el
            : null
        )
      );
    }
    if (type === 2) {
      setList(
        participants.filter((el) =>
          el.workplace.toLowerCase().includes(input) ? el : null
        )
      );
    }
    if (type === 3) {
      setList(
        participants.filter((el) =>
          el.jobDescription.toLowerCase().includes(input) ? el : null
        )
      );
    }
  }
  return (
    <ParticipantsWrapper $open={show}>
      <ModalHeader>
        <Label
          text={t('book-course-reserve-button')}
          fontSize={"20px"}
          textAlign={"center"}
          color={Color.WHITE}
        />
      </ModalHeader>
      <SearchContainer>
        <SearchColumn>
          <CustomSearch
            name={t("reserved-courses-detail-header-name")}
            onSearch={(input) => searchParticipant(participants, input, 1)}
            width={'260px'}
            labelProps={{
              color: Color.LIGHT_BLUE,
              text: t("reserved-courses-detail-header-name"),
              textAlign: 'left',
              textTransform: 'uppercase',
              fontWeight: 'normal',
              fontSize: '12px'
            }}
          />
        </SearchColumn>
        <SearchColumn>
          <CustomSearch
            name={t("employee-registry-dropdown-headquarter")}
            onSearch={(input) => searchParticipant(participants, input, 2)}
            label={t("employee-registry-dropdown-headquarter-placeholder")}
            labelProps={{
              color: Color.LIGHT_BLUE,
              text: t("book-course-search-headquarter"),
              textAlign: 'left',
              textTransform: 'uppercase',
              fontWeight: 'normal',
              fontSize: '12px'
            }}
          />
        </SearchColumn>
        <SearchColumn>
          <CustomSearch
            name={t("employee-registry-dropdown-job-description")}
            onSearch={(input) => searchParticipant(participants, input, 3)}
            label={t("employee-registry-dropdown-job-description-placeholder")}
            labelProps={{
              color: Color.LIGHT_BLUE,
              text: t("reserved-courses-detail-header-job-description"),
              textAlign: 'left',
              textTransform: 'uppercase',
              fontWeight: 'normal',
              fontSize: '12px'
            }}
          />
        </SearchColumn>
      </SearchContainer>
      <TableHeaderContainer>
        <Label
          text={t("reserved-courses-detail-header-name")}
          color={Color.LIGHT_BLUE}
          textAlign={"center"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"50%"}
        />
        <Label
          text={t("reserved-courses-detail-header-headquarter")}
          color={Color.LIGHT_BLUE}
          textAlign={"center"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"70%"}
        />
        <Label
          text={t("reserved-courses-detail-header-job-description")}
          color={Color.LIGHT_BLUE}
          textAlign={"center"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"25%"}
        />
        <Label
          text={t('book-course-reserve-button')}
          color={Color.LIGHT_BLUE}
          textAlign={"center"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"28%"}
        />
      </TableHeaderContainer>
      <ListWrapper>
        {!loading && participantList.map((singleParticipant, index) => {
          return (
            <SingleParticipant
              participant={singleParticipant}
              key={
                singleParticipant.cf
              }
              onAdd={() =>
                handleSelected(
                  singleParticipant.cf
                )
              }
              index={index}
              checked={selected.has(
                  (singleParticipant.cf)
              )}
            />
          );
        })}
      </ListWrapper>
      <EditButtonsContainer>
        <Button
          label={t('book-course-reserve-button')}
          color={Color.LIGHT_BLUE}
          icon={Icons.COMMONS.CALENDAR}
          onClick={ () => reserveCourseDate(variationId, Array.from(selected))!!.then(setShow)}
        />
        <Button
          label={t("close")}
          color={Color.RED}
          icon={Icons.BUTTONS.CANCEL}
          onClick={setShow}
        />
      </EditButtonsContainer>
    </ParticipantsWrapper>
  );
};

export default BookModal;
