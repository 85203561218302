import styled from "styled-components";
//import {useTranslation} from "react-i18next";
import { Employee } from "../../../../types";
import { Color } from "../../../../constants/color";
import Label from "../../../label";
import { Status } from "../../../../constants/status";

interface Props {
  employee: Employee;
  onAdd?: (name: string) => void;
  index: number;
  checked?: boolean;
}

const SingleParticipantsWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 2.6rem;
  //width: 1240px;
  border-bottom: 1px solid ${Color.LIGHT_BLUE};
  &:last-child {
    border-bottom: 0;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-grow: 400;
  justify-content: start;
  padding-left: 1rem;
  //column-gap: 5rem;
`;

const SingleEmployee = ({ employee, onAdd, checked }: Props) => {
  //const { t } = useTranslation();
  //const [s, setS] = useState(checked)
  const handleSelected = () =>
    onAdd && onAdd(employee.lastName + " " + employee.firstName);
  console.log(handleSelected);
  console.log(checked);
  console.log("Single employee", typeof employee.hireDate);
  // const hireDate = format(employee.hireDate, "dd/MM/yyyy");
  return (
    <SingleParticipantsWrapper>
      <LabelContainer>
        <Label
          text={Status.get(employee.state)!!.label}
          color={Color.LIGHT_BLUE}
          textAlign={"left"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"5.4rem"}
        />
        <Label
          text={employee.lastName}
          color={Color.LIGHT_BLUE}
          textAlign={"left"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"6.4rem"}
        />
        <Label
          text={employee.firstName}
          color={Color.LIGHT_BLUE}
          textAlign={"left"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"7rem"}
        />
        <Label
          text={employee.cf}
          color={Color.LIGHT_BLUE}
          textAlign={"left"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"9rem"}
        />
        <Label
          text={employee.jobDescription}
          color={Color.LIGHT_BLUE}
          textAlign={"center"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"11rem"}
        />
        <Label
          text={employee.workplace}
          color={Color.LIGHT_BLUE}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          textAlign={"center"}
          width={"6rem"}
        />
        <Label
          text={`${employee.hireDate}`}
          color={Color.LIGHT_BLUE}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          textAlign={"center"}
          width={"6rem"}
        />
        <Label
          text={employee.cessationDate ? `${employee.cessationDate}` : ""}
          color={Color.LIGHT_BLUE}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          textAlign={"center"}
          width={"7rem"}
        />
        <Label
          text={employee.email}
          color={Color.LIGHT_BLUE}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"10px"}
          textAlign={"left"}
          width={"12.8rem"}
        />
        <Label
          text={employee.phone}
          color={Color.LIGHT_BLUE}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          textAlign={"center"}
        />
      </LabelContainer>
    </SingleParticipantsWrapper>
  );
};

export default SingleEmployee;
