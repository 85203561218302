import styled from "styled-components";
import {LabelProps} from "../../types";
import {Color} from "../../constants/color";

const StyledTitle = styled.div`
    color: ${Color.LIGHT_BLUE};
    font-size: 40px;
    font-family: "Avenir Next Bold", sans-serif;
    font-weight: bold;
    text-align: center;
`;

const Title = ({text}: LabelProps) => {
    return (
        <StyledTitle>{text}</StyledTitle>
    )
}

export default Title