import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Label from "../../label";
import Textbox from "../../form/input";
import {Color} from "../../../constants/color";
import {BreakpointsQuery} from "../../../constants/device";
import {UserPageResponse} from "../../../types";


interface Props {
  edit: boolean;
  companyData: UserPageResponse | undefined;
  editCompanyData: (field: string, event: KeyboardEvent) => void;

}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 80px;
    margin-top: 30px;
    input {
        background-color: ${Color.WHITE};
        box-shadow: none;
        border-style: solid;
        border-width: 2px;
        border-color: ${Color.LIGHT_BLUE};
        border-radius: 20px;
        width: 480px;
        height: 30px;
        @media ${BreakpointsQuery.tablet} {
            width: 400px;
        }
    }
    input:focus {
        outline-width: 0;
    }
    @media ${BreakpointsQuery.tablet} {
        column-gap: 20px;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 30px;
`;

const MainInfo = ({edit, companyData, editCompanyData} : Props) => {
  const { t } = useTranslation();
  return (
      <MainContainer>
        <Label text={t('user-main-info-label')}
               fontSize={'26px'}
               fontWeight={'bold'}
               textTransform={'uppercase'}
        />
        <InfoContainer>
          <Column>
              <Textbox label={t('user-business-name')}
                       alt={t('user-business-name')}
                       autocomplete={'off'}
                       form={'company-info'}
                       name={t('user-business-name')}
                       placeholder={companyData ? companyData.businessName : ''}
                       type={'text'}
                       textAlign={'left'}
                       onChange={(e) => {
                         editCompanyData('businessName', e)
                       }}
                       required={false}
                       edit={edit}
              />
              <Textbox label={t('user-contact-first-name')}
                       alt={t('user-contact-first-name')}
                       autocomplete={'off'}
                       form={'company-info'}
                       name={t('user-contact-first-name')}
                       placeholder={companyData ? companyData.adminFirstName : ''}
                       type={'text'}
                       textAlign={'left'}
                       onChange={(e) => {
                         editCompanyData('adminFirstName', e)
                       }}
                       required={false}
                       edit={edit}
              />
              <Textbox label={t('user-contact-phone')}
                       alt={t('user-contact-phone')}
                       autocomplete={'off'}
                       form={'company-info'}
                       name={t('user-contact-phone')}
                       placeholder={companyData ? companyData.adminPhone : ''}
                       type={'text'}
                       textAlign={'left'}
                       onChange={(e) => {
                         editCompanyData('adminPhone', e)
                       }}
                       required={false}
                       edit={edit}
              />
              <Textbox label={t('user-sdi-code')}
                       alt={t('user-sdi-code')}
                       autocomplete={'off'}
                       form={'company-info'}
                       name={t('user-sdi-code')}
                       placeholder={companyData ? companyData.companyInfo.sdiCode : ''}
                       type={'text'}
                       textAlign={'left'}
                       onChange={(e) => {
                         editCompanyData('companyInfo.sdiCode', e)
                       }}
                       required={false}
                       edit={edit}
              />
          </Column>
          <Column>
              <Textbox label={t('user-fiscal-code')}
                       alt={t('user-fiscal-code')}
                       autocomplete={'off'}
                       form={'company-info'}
                       name={t('user-fiscal-code')}
                       placeholder={companyData ? companyData.vatNumber : ''}
                       type={'text'}
                       textAlign={'left'}
                       onChange={(e) => {
                         editCompanyData('companyInfo.vatNumber', e)
                       }}
                       required={false}
                       edit={edit}
              />
              <Textbox label={t('user-contact-last-name')}
                       alt={t('user-contact-last-name')}
                       autocomplete={'off'}
                       form={'company-info'}
                       name={t('user-contact-last-name')}
                       placeholder={companyData ? companyData.adminLastName : ''}
                       type={'text'}
                       textAlign={'left'}
                       onChange={(e) => {
                         editCompanyData('adminLastName', e)
                       }}
                       required={false}
                       edit={edit}
              />
              <Textbox label={t('user-email')}
                       alt={t('user-email')}
                       autocomplete={'off'}
                       form={'company-info'}
                       name={t('user-email')}
                       placeholder={companyData ? companyData.adminEmail : ''}
                       type={'text'}
                       textAlign={'left'}
                       onChange={(e) => {
                         editCompanyData('adminEmail', e)
                       }}
                       required={false}
                       edit={edit}
              />
              <Textbox label={t('user-cem')}
                       alt={t('user-cem')}
                       autocomplete={'off'}
                       form={'company-info'}
                       name={t('user-cem')}
                       placeholder={companyData ? companyData.cem : ''}
                       type={'text'}
                       textAlign={'left'}
                       onChange={(e) => {
                         editCompanyData('cem', e)
                       }}
                       required={false}
                       edit={edit}
              />
          </Column>
        </InfoContainer>
      </MainContainer>
  );
}

export default MainInfo