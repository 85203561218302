import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {Employee} from "../../../../types";
import CustomSearch from "../../../search";
import { Color } from "../../../../constants/color";
import SingleParticipant from "./single-participant";
import Label from "../../../label";
import Button from "../../../button/button";
import { Icons } from "../../../../constants/icons";
import { useState } from "react";
import {BreakpointsQuery} from "../../../../constants/device";

interface Props {
  participants: Employee[];
  show: boolean;
}

const ParticipantsWrapper = styled.div<{
  $open: boolean;
}>`
  display: ${($open) => ($open.$open ? "flex" : "none")};
  transition: display 2s;
  flex-direction: column;
  align-items: center;
  row-gap: 0.8rem;
  max-height: 24rem;
  width: 100%;
  background-color: ${Color.CERAMIC};
  box-shadow: 0 0 8px 6px ${Color.SHADOW};
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  ::-webkit-scrollbar {
    position: relative;
    display: flex;
    left: 4rem;
    width: 0.6rem;
    border-radius: 4rem;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 4rem;
    background-color: ${Color.GREY};
  }
  @media ${BreakpointsQuery.tablet} {
      width: 990px;
  }
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.4rem;
  margin-left: auto;
  margin-right: auto;
  width: 50rem;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  min-width: max-content;
  overflow-x: visible;
  overflow-y: auto;
  max-height: 22rem;
`;

const EditButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 4rem;
  margin-top: 0.4rem;
`;



const Participants = ( {show ,participants} : Props ) => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [participantList, setList] = useState(participants);
    function removeParticipant(participants : Employee[] , index : number) {
        setList(participants.filter( el => el !== participants[index]))
    }
    function searchParticipant(participants : Employee[] , input : string) {
        setList(participants.filter(el => (el.lastName.toLowerCase() + ' ' + el.firstName.toLowerCase()).includes(input) ? el : null))
    }
    return (
        <ParticipantsWrapper $open={show}>
            <CustomSearch name={t('reserved-courses-detail-search-placeholder')}
                          onSearch={ (input) => searchParticipant(participants, input)}/>
            <TableHeaderContainer>
                <Label text={t('reserved-courses-detail-header-name')}
                       color={Color.LIGHT_BLUE}
                       textAlign={'center'}
                       textTransform={'uppercase'}
                       fontWeight={'normal'}
                       fontSize={'12px'}
                       width={'30%'}
                />
                <Label text={t('reserved-courses-detail-header-headquarter')}
                       color={Color.LIGHT_BLUE}
                       textAlign={'center'}
                       textTransform={'uppercase'}
                       fontWeight={'normal'}
                       fontSize={'12px'}
                       width={'30%'}
                />
                <Label text={t('reserved-courses-detail-header-job-description')}
                       color={Color.LIGHT_BLUE}
                       textAlign={'center'}
                       textTransform={'uppercase'}
                       fontWeight={'normal'}
                       fontSize={'12px'}
                       width={'30%'}
                />
            </TableHeaderContainer>
            <ListWrapper>
                {participantList.map((singleParticipant, index) => {
                    return (
                        <SingleParticipant
                            participant={singleParticipant}
                            key={index}
                            edit={edit}
                            onDelete={ () => removeParticipant(participantList, index)}
                        />
                    );
                })}
            </ListWrapper>
            <EditButtonsContainer>
                <Button label={t('reserved-courses-detail-button-edit')}
                        color={Color.RED}
                        icon={Icons.COMMONS.ARROW}
                        onClick={ () => setEdit(!edit)}
                />
                <Button label={t('reserved-courses-detail-button-add')}
                        color={Color.GREEN}
                        icon={Icons.COMMONS.ARROW}
                />
                <Button label={t('reserved-courses-detail-button-save')}
                        color={Color.LIGHT_BLUE}
                        icon={Icons.COMMONS.ARROW}
                />
            </EditButtonsContainer>

        </ParticipantsWrapper>
    );
};

export default Participants;
