import React, { useEffect } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import styled from "styled-components";

import {
  Select,
  SelectItem,
  SelectLabel,
  SelectProvider,
} from "../form/select";

import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";
import { useAuth } from "../../provider/authProvider";

import * as Ariakit from "@ariakit/react";
import {
  Bell,
  CalendarDays,
  ChevronDown,
  FileDown,
  Search,
} from "lucide-react";
import { getCourseIcon, goToPage } from "../../utils/utils";
import axios from "axios";
import { BE_BASE_URL, ATTESTATO_DOC_TYPE_ID } from "../../utils/config";
import { add, format, isAfter, isBefore, parse } from "date-fns";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 4rem;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 2rem;
  @media ${BreakpointsQuery.tablet} {
    justify-content: center;
    gap: 1rem;
  }
`;

const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  & label {
    margin-left: 0.5rem;
    font-family: Avenir Next, sans-serif;
    font-weight: bold;
    color: ${Color.LIGHT_BLUE};
  }
  & input {
    border: 1px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    padding: 0.2rem 1rem;
    width: 100%;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.LIGHT_BLUE};
  color: ${Color.WHITE};
  top: 0;
  right: 0;
  bottom: 0;
  width: 2.5rem;
  border-radius: 0 1rem 1rem 0;
`;

type FiltersType = {
  corso: string;
  anno: string;
  sede: string;
  stato: number;
};

type CourseType = {
  id: number;
  fileName: string;
  tipo: number;
  course: string;
  name: string;
  headquarter: string;
  hours: number;
  date_exp: string;
  status_expire: number;
  status: number;
};

const statoAttestati: { [key: number]: string } = {
  0: "TUTTI",
  1: "VALIDO",
  2: "IN SCADENZA",
  3: "SCADUTO",
  4: "NUOVI",
};

export default function RicercaPerCorso() {
  const { token } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const filterStatus = urlParams.get("status") || 0;

  const [corsi, setCorsi] = React.useState<Map<string, string>>(new Map());
  const [attestati, setAttestati] = React.useState<Map<string, CourseType[]>>(
    new Map()
  );

  const [filters, setFilters] = React.useState<FiltersType>({
    corso: "",
    anno: "",
    sede: "",
    stato: +filterStatus,
  });

  const debouncedFilters = useDebounce(filters, 300);

  const selectStato = Ariakit.useSelectStore({
    value: `${filters.stato}`,
    setValue: (value) => {
      // console.log("Selected value", value);
      setFilters((prev) => ({ ...prev, stato: +value }));
    },
  });

  useEffect(() => {
    async function fetchAttestati(filters: FiltersType) {
      const url = `${BE_BASE_URL}/certificates`;
      const params = new URLSearchParams();
      params.append("page", "1");
      params.append("size", "1000");
      params.append("order", "titolo_corso");
      params.append("dTId", ATTESTATO_DOC_TYPE_ID.toString());
      if (filters.corso) {
        params.append("titolo_corso", filters.corso);
      }
      if (filters.anno && filters.anno.length === 4 && !isNaN(+filters.anno)) {
        // build two dates for the year
        const startDate = new Date(+filters.anno, 0, 1);
        const endDate = new Date(+filters.anno, 11, 31);
        params.append(
          "inizio_corso",
          `${format(startDate, "yyyy-MM-dd")},${format(endDate, "yyyy-MM-dd")}`
        );
      }
      if (filters.sede) {
        params.append("sede_azienda", filters.sede);
      }
      const result = await axios.get(`${url}?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const titoloCorsiMap: Map<string, string> = new Map();
      const corsiMap: Map<string, CourseType[]> = new Map();
      if (result.data && result.data.ok) {
        let corsi = result.data.content.data;
        console.log("Attestati ricerca per corsi: ", corsi);
        corsi.forEach((c: any) => {
          const metadata = JSON.parse(c.metadata);
          const fineCorso = parse(
            metadata.fine_corso,
            "yyyy-MM-dd",
            new Date()
          );
          const scadenza = add(fineCorso, { years: metadata.durata });
          const inScadenza = add(scadenza, { months: -6 });
          const statusExpire = isBefore(scadenza, new Date())
            ? 3
            : isAfter(inScadenza, new Date())
            ? 1
            : 2;
          // Filtro stato attestato lo possiamo fare solo in pagina per ora
          // il documentale non supporta filtri su campi calcolati
          // SE ho il filtro definito e lo stato è differente non và visualizzato
          if (
            debouncedFilters.stato &&
            statusExpire !== debouncedFilters.stato
          ) {
            return;
          }
          const dataPrenotazione =
            metadata.data_prenotazione &&
            parse(
              metadata.data_prenotazione,
              "yyyy-MM-dd HH:mm:ss.S",
              new Date()
            );

          const cData = {
            id: c.id,
            fileName: c.fileName,
            tipo: metadata.tipo_corso,
            code: metadata.codice_corso,
            course: metadata.titolo_corso,
            name: `${metadata.cognome_discente} ${metadata.nome_discente}`,
            cf: metadata.cf_discente,
            headquarter: metadata.sede_azienda,
            hours: metadata.durata,
            date_exp: format(scadenza, "dd/MM/yyyy"),
            status_expire: statusExpire,
            date_renew:
              dataPrenotazione && format(dataPrenotazione, "dd/MM/yyyy"),
            status: dataPrenotazione ? 2 : 1,
          };
          if (corsiMap.has(metadata.codice_corso)) {
            let cDataArray = corsiMap.get(metadata.codice_corso);
            if (cDataArray) {
              cDataArray.push(cData);
            } else {
              cDataArray = [cData];
            }
            corsiMap.set(metadata.codice_corso, cDataArray);
          } else {
            titoloCorsiMap.set(metadata.codice_corso, metadata.titolo_corso);
            corsiMap.set(metadata.codice_corso, [cData]);
          }
        });
      }
      return { corsiMap, titoloCorsiMap };
    }

    fetchAttestati(debouncedFilters).then(({ corsiMap, titoloCorsiMap }) => {
      console.log("Attestati filtrati: ", corsiMap, titoloCorsiMap);
      setAttestati(corsiMap as any);
      setCorsi(titoloCorsiMap as any);
    });
  }, [debouncedFilters, token]);

  return (
    <Layout>
      <SearchContainer>
        <SearchInput>
          <label htmlFor="searchCorso">CORSO</label>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              name="searchCorso"
              type="text"
              placeholder="Ricerca per corso"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, corso: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <SearchInput>
          <label htmlFor="searchAnno">ANNO</label>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              name="searchAnno"
              type="text"
              placeholder="Ricerca per anno"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, anno: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <SearchInput>
          <label htmlFor="searchSede">SEDE AZIENDALE</label>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              name="searchSede"
              type="text"
              placeholder="Ricerca per sede"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, sede: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        {/* Nascondiamo finchè non implementiamo il filtro */}
        <div>
          <SelectProvider store={selectStato}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.1rem",
              }}
            >
              <SelectLabel
                style={{
                  paddingLeft: "0.5rem",
                }}
              >
                STATO
              </SelectLabel>
              <Select
                style={{
                  minWidth: "200px",
                }}
                renderValue={(value) => statoAttestati[+value]}
              >
                {Object.entries(statoAttestati).map(([key, value]) => (
                  <SelectItem key={`statoAttestato_${key}`} value={key}>
                    {value}
                  </SelectItem>
                ))}
              </Select>
            </div>
          </SelectProvider>
        </div>
      </SearchContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          width: "78rem",
        }}
      >
        {Array.from(attestati.entries()).map(([key, value]) => {
          const titleCorso = corsi.get(key) || key;
          return (
            <div
              key={key}
              style={{
                width: "100%",
                filter: "drop-shadow(4px -4px 5px var(--shadow800))",
              }}
            >
              <CourseAccordion
                icon={getCourseIcon(value ? value[0].tipo : -1)}
                title={titleCorso}
                code={key}
                attestati={value}
              />
            </div>
          );
        })}
      </div>
    </Layout>
  );
}

const Disclosure = styled(Ariakit.Disclosure)`
  border-radius: 0.8rem;
  background-color: ${Color.WHITE};
  width: 100%;
  position: relative;
  overflow: hidden;
  border: none;
  padding: 0.5rem 1.5rem;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    width: 1rem;
    background-color: ${Color.LIGHT_BLUE};
  }

  & p[role='button'] {
    position: absolute;
    right: 0;
    top: 12px;
    bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
    border-radius: 0.8rem 0 0 0.8rem;
    background-color: ${Color.GREEN};
    color: ${Color.WHITE};
    font-weight: bold;
`;

const DisclosureContent = styled(Ariakit.DisclosureContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: calc(100% - 0.8rem);
  margin: -8px auto 0 auto;

  background-color: ${Color.CERAMIC};
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  padding: 1.6rem 2rem;
  ::-webkit-scrollbar {
    position: relative;
    display: flex;
    left: 4rem;
    width: 0.6rem;
    border-radius: 4rem;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 4rem;
    background-color: ${Color.GREY};
  }
`;

const TableAttestati = styled.div`
  display: grid;
  grid-template-columns: auto 150px 200px 150px 150px 100px 60px;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: bold;
`;

const TableAttestatiHeader = styled(TableAttestati)`
  text-transform: uppercase;
  color: ${Color.LIGHT_BLUE};
`;

const TableAttestatiRow = styled(TableAttestati)`
  border: 1px solid ${Color.GREY};
  border-radius: 16px;
  text-transform: uppercase;
  color: ${Color.DARK_GREY};
  background-color: ${Color.LIGHT_GREY};
  min-height: 3.2rem;
`;

const OutlineButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${Color.WHITE};
  background-color: ${Color.BLUE};
  border: 0;
  padding: 0.3em 1.5em;
  border-radius: 8px;
  font-size: 1.6em;
  font-weight: bold;
  cursor: pointer;
`;

function CourseAccordion({
  icon,
  title,
  code,
  attestati,
}: {
  icon?: React.ReactNode;
  title: string;
  code: string;
  attestati: any[];
}) {
  const { token } = useAuth();

  const [filterdAttestati, setFilteredAttestati] = React.useState(attestati);
  const [partecipantiCorso, setPartecipantiCorso] = React.useState<string[]>(
    []
  );
  const [searchPartecipante, setSearchPartecipante] = React.useState("");

  const debauncedSearchPartecipante = useDebounce(searchPartecipante, 300);

  // let courseParticipants = useContext(participantsContext);
  // console.log("Ricerca per corso: corsi nel context", courseParticipants);

  function handlePartecipanteCorso(cf: string) {
    if (partecipantiCorso.includes(cf)) {
      setPartecipantiCorso((prev) => prev.filter((p) => p !== cf));
    } else {
      setPartecipantiCorso((prev) => [...prev, cf]);
    }
  }

  useEffect(() => {
    if (debauncedSearchPartecipante) {
      setFilteredAttestati(
        attestati.filter((a) =>
          a.cf.toLowerCase().includes(debauncedSearchPartecipante.toLowerCase())
        )
      );
    } else {
      setFilteredAttestati(attestati);
    }
  }, [debauncedSearchPartecipante, attestati]);

  function renderScadenza(attestato: any) {
    const color =
      attestato.status_expire === 1
        ? Color.GREEN
        : attestato.status_expire === 2
        ? Color.YELLOW
        : attestato.status_expire === 3
        ? Color.RED
        : "transparent";
    const label =
      attestato.status_expire === 1
        ? "VALIDO FINO AL "
        : attestato.status_expire === 2
        ? "SCADE IL "
        : attestato.status_expire === 3
        ? "SCADUTO IL "
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
        }}
      >
        <Bell color={color} fill={color} width={24} height={24} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ".2rem",
            color,
          }}
        >
          <p>{label}</p>
          <p>{attestato.date_exp}</p>
        </div>
      </div>
    );
  }

  function renderInfo(attestato: any) {
    const label =
      attestato.status === 1
        ? ""
        : attestato.status === 2
        ? "PRENOTATO IL " + attestato.date_renew
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
          color: Color.BLUE,
        }}
      >
        {attestato.status === 2 ? (
          <CalendarDays color={Color.BLUE} width={24} height={24} />
        ) : null}
        <p>{label}</p>
      </div>
    );
  }

  async function handleDownloadAttestato(id: number, fileName: string) {
    const url = `${BE_BASE_URL}/certificates/${id}`;
    const response = await axios({
      url,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);
    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  return (
    <Ariakit.DisclosureProvider>
      <Disclosure>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2rem",
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: Color.LIGHT_BLUE,
              fontFamily: "Avenir Next Bold",
            }}
          >
            {icon}
            <p>{title}</p>
          </div>
          <p role="button">
            <ChevronDown color={Color.WHITE} size={32} />
          </p>
        </div>
      </Disclosure>
      <DisclosureContent>
        <SearchInput style={{ width: "90%" }}>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              name="searchPartecipantiCorso"
              type="text"
              placeholder="Ricerca i partecipanti"
              onChange={(e) => setSearchPartecipante(e.target.value)}
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: ".5rem",
          }}
        >
          <TableAttestatiHeader>
            <p>Nome e cognome</p>
            <p>Sede aziendale</p>
            <p>Ore di formazione</p>
            <p>Stato</p>
            <p>Info</p>
            <p
              style={{
                justifySelf: "center",
              }}
            >
              Attestato
            </p>
          </TableAttestatiHeader>
          {filterdAttestati.map((a) => (
            <TableAttestatiRow key={`attestato_${a.id}`}>
              <p>{a.name}</p>
              <p>{a.headquarter}</p>
              <p>{a.hours} ore</p>
              <p>{renderScadenza(a)}</p>
              <p>{renderInfo(a)}</p>
              <FileDown
                color={Color.LIGHT_BLUE}
                width={24}
                height={24}
                style={{
                  justifySelf: "center",
                  cursor: "pointer",
                }}
                onClick={(e) => handleDownloadAttestato(a.id, a.fileName)}
              />
              <p
                style={{
                  justifySelf: "center",
                }}
              >
                <input
                  type="checkbox"
                  onClick={(e) => handlePartecipanteCorso(a.cf.toLowerCase())}
                />
              </p>
            </TableAttestatiRow>
          ))}
        </div>
        {filterdAttestati?.length > 0 && (
          <OutlineButton
            style={{
              marginLeft: "auto",
              marginTop: "-1rem",
            }}
            onClick={() => {
              if (partecipantiCorso && partecipantiCorso.length > 0) {
                goToPage(
                  "corso",
                  `${code.toLowerCase()}/-1/${partecipantiCorso
                    .join(",")
                    .concat(",")}`
                );
              } else {
                alert(
                  "Selezionare almeno un partecipante per effettura una prenotazione"
                );
              }
            }}
          >
            <CalendarDays color={Color.WHITE} />
            PRENOTA
          </OutlineButton>
        )}
      </DisclosureContent>
    </Ariakit.DisclosureProvider>
  );
}
