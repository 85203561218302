import styled from "styled-components";
import SingleEmployee from "./employee";
import Label from "../../label";
import {Color} from "../../../constants/color";
import {BreakpointsQuery} from "../../../constants/device";
import {Employee} from "../../../types";
import {useTranslation} from "react-i18next";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BE_BASE_URL } from "../../../utils/config";
interface Props {
    employeeList: Employee[];
}

const ModalWrapper = styled.div<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: ${(props) => (props.isVisible ? 999 : -1)};
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  max-width: 100%;
`;

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 1240px;
    overflow: auto;
    ::-webkit-scrollbar {
        position: relative;
        display: flex;
        left: 4rem;
        width: 0.6rem;
        border-radius: 4rem;
    }
    ::-webkit-scrollbar-button {
        display: none;
    }
    ::-webkit-scrollbar-track {
        border-radius: 4rem;
        background-color: ${Color.LIGHT_BLUE};
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4rem;
        background-color: ${Color.LIGHT_BLUE};
    }
    ::-webkit-scrollbar-track-piece {
        border-radius: 4rem;
        background-color: ${Color.GREY};
    }
    @media ${BreakpointsQuery.tablet} {
        width: 960px;
    }
`;

const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    justify-content: start;
    width: fit-content;
`;

const HeaderMargin = styled.div`
    width: 1240px;
    height: 0.2rem;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    background-color: ${Color.LIGHT_BLUE};
    @media ${BreakpointsQuery.tablet} {
        width: 1240px;
    }
`;

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
    min-width: max-content;
    overflow-x: visible;
    overflow-y: auto;
    height: 22rem;
    justify-content: flex-start;
`;

const TableRow = styled.div`
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: ${Color.LIGHT_GREY};
    }
`;

const EmployeeTable = ({employeeList} : Props) => {
    const {t} = useTranslation();
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
     const [nominations, setNominations] = useState<any[]>([]);
        const [specificRisks, setSpecificRisks] = useState<any[]>([]);

         // Fetch Nominations and Specific Risks
         useEffect(() => {
           if (selectedEmployee) {
             axios.get<{ id: number, nomine: string }[]>(`${BE_BASE_URL}/nominations/${selectedEmployee.id}`)
               .then((response) => setNominations(response.data))
               .catch((error: any) => console.error("Error fetching nominations", error));

             axios.get<{ id: number, specificRisk: string }[]>(`${BE_BASE_URL}/specificRisks/${selectedEmployee.id}`)
               .then((response) => setSpecificRisks(response.data))
               .catch((error: any) => console.error("Error fetching specific risks", error));
           }
         }, [selectedEmployee]);

    // Gestisci il click su una riga della tabella
   const handleRowClick = (employee: Employee) => {
       console.log("Riga cliccata per l'impiegato:", employee); // Debugging
       setSelectedEmployee(employee);
       setIsModalVisible(true);
   };

    // Chiudi la modale
    const closeModal = () => {
        console.log("Modale chiusa"); // Debugging
        setIsModalVisible(false);
    };

    return (
        <TableWrapper>
            <TableHeader>
                <Label text={t('employee-registry-dropdown-state')}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       textAlign={"center"}
                       width={"4rem"}
                       paddingLeft={4}
                />
                <Label text={t('reserved-courses-detail-header-last-name')}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       textAlign={"left"}
                       width={"4.6rem"}
                />
                <Label text={t('reserved-courses-detail-header-first-name')}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       textAlign={"left"}
                       width={"4.8rem"}
                />
                <Label text={t('employee-registry-table-tax-code')}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       width={"10.2rem"}
                />
                <Label text={t('reserved-courses-detail-header-job-description')}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       width={"5.4rem"}
                />
                <Label text={t('employee-registry-table-workplace')}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       width={"6.4rem"}
                />
                <Label text={t('employee-registry-table-hire-date')}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       width={'4.5rem'}
                />
                <Label text={t('employee-registry-table-cessation-date')}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       width={'4.5rem'}
                />
                <Label text={t('employee-registry-table-email')}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                       width={'8.6rem'}
                />
                <Label text={t('employee-registry-table-phone')}
                       fontSize={'14px'}
                       textTransform={'uppercase'}
                />
            </TableHeader>

            <HeaderMargin />

            <ListWrapper>
                {employeeList && employeeList.map((singleEmployee, index) => {
                    return (
                        <TableRow
                            key={singleEmployee.lastName + ' ' + singleEmployee.firstName}
                            onClick={() => handleRowClick(singleEmployee)}
                        >
                            <SingleEmployee
                                employee={singleEmployee}
                                key={singleEmployee.lastName + ' ' + singleEmployee.firstName}
                                index={index}
                            />
                        </TableRow>
                    );
                })}
            </ListWrapper>

            {/* Modale per mostrare i dettagli dell'impiegato */}
            <ModalWrapper isVisible={isModalVisible}>
                <ModalContent>
                    <h2>Dettagli dell'impiegato</h2>
                    {selectedEmployee && (
                        <div>
                            <p><strong>Nome:</strong> {selectedEmployee.firstName} {selectedEmployee.lastName}</p>
                            <p><strong>Mansione:</strong> {selectedEmployee.jobDescription}</p>
                            {/* Aggiungi altri dettagli */}
                        </div>
                    )}
                     {/* Nominations Table */}
                                        <h3>Nomine</h3>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Nomina</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {nominations.map((nomination) => (
                                                    <tr key={nomination.id}>
                                                        <td>{nomination.id}</td>
                                                        <td>{nomination.nomine}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        {/* Specific Risks Table */}
                                        <h3>Rischi Specifici</h3>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Rischio Specifico</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {specificRisks.map((risk) => (
                                                    <tr key={risk.id}>
                                                        <td>{risk.id}</td>
                                                        <td>{risk.specificRisk}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                    <button onClick={closeModal}>Chiudi</button>
                </ModalContent>
            </ModalWrapper>
        </TableWrapper>
    );
};

export default EmployeeTable