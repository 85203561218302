import styled from "styled-components";
import {Color} from "../../../../constants/color";
import {Icons} from "../../../../constants/icons";
import Label from "../../../label";
import Button from "../../../button/button";
import {CourseCarouselItem} from "../../../../types";
import {Building2, MapPin, Users} from "lucide-react";
import {dateOptions, getCourseCover, getCourseMode, getLocale, goToPage} from "../../../../utils/utils";
import {useTranslation} from "react-i18next";

interface Props {
    item: CourseCarouselItem
}

const StyledItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${Color.WHITE};
    box-shadow: 0 0 20px 10px ${Color.SHADOW};
    font-weight: bolder;
    border-radius: 20px;
    width: 16rem;
    height: 28rem;
    button {
        margin-bottom: 1.2rem;
    }
`;

const CourseImage = styled.div`
    height: 7.4rem;
    min-width: 100%;
    display: flex;
    justify-content: center;
    
    img {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
`;

const DateContainer = styled.div`
    background-color: ${Color.LIGHT_BLUE};
    min-height: 2.2rem;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        margin-top: 0.2rem;
    }
`;

const Name = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 14rem;
    min-width: 100%;

    div {
        text-align: center;
        margin: 1.2rem 2.2rem;
    }
`;

const Info = styled.div`
    display: flex;
    row-gap: 0.6rem;
    flex-direction: column;
    align-items: start;
    min-width: 100%;
    margin-bottom: 1rem;
`;

const InfoRow = styled.div`
    display: flex;
    max-width: 14rem;
    margin-left: 2rem;
    column-gap: 1rem;
    align-items: center;
    img {
        width: 1.4rem;
        max-width: 100%;
    }
`;

const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    div {
        text-align: left;
        max-width: 8rem;
    }
`;

const CarouselItem = ( {item} : Props ) => {
  const { t } = useTranslation();
  const date : Date = new Date(item.date.toString());
  const formattedDate = date.toLocaleDateString(getLocale(), dateOptions)
    return (
        <StyledItem>
            <CourseImage>
                <img alt={'Immagine corso'} src={getCourseCover(item.courseName)}/>
            </CourseImage>
            <DateContainer>
                <Label text={formattedDate}
                       textTransform={'uppercase'}
                       fontWeight={'bold'}
                       fontSize={'18px'}
                       textAlign={'center'}
                       color={Color.WHITE}
                />
            </DateContainer>
            <Name>
                <Label text={item.courseName}
                       textTransform={'uppercase'}
                       fontWeight={'bold'}
                       fontSize={'14px'}
                       textAlign={'center'}
                       color={Color.LIGHT_BLUE}
                />
            </Name>
            <Info>
                <InfoRow>
                  <Users color={Color.LIGHT_BLUE}/>
                    <InfoColumn>
                        <Label text={t('book-course-lecture-mode')}
                               textTransform={'uppercase'}
                               fontWeight={'bold'}
                               fontSize={'10px'}
                               textAlign={'center'}
                               color={Color.DARK_GREY}
                        />
                        <Label text={t(getCourseMode(item.mode))}
                               textTransform={'uppercase'}
                               fontWeight={'bold'}
                               fontSize={'13px'}
                               textAlign={'center'}
                               color={Color.LIGHT_BLUE}
                        />
                    </InfoColumn>
                </InfoRow>
                <InfoRow>
                  <MapPin color={Color.LIGHT_BLUE}/>
                    <InfoColumn>
                        <Label text={t('book-course-city')}
                               textTransform={'uppercase'}
                               fontWeight={'bold'}
                               fontSize={'10px'}
                               textAlign={'center'}
                               color={Color.DARK_GREY}
                        />
                        <Label text={item.city}
                               textTransform={'uppercase'}
                               fontWeight={'bold'}
                               fontSize={'13px'}
                               textAlign={'center'}
                               color={Color.LIGHT_BLUE}
                        />
                    </InfoColumn>
                </InfoRow>
                <InfoRow>
                  <Building2 color={Color.LIGHT_BLUE}/>
                    <InfoColumn>
                        <Label text={t('book-course-headquarter')}
                               textTransform={'uppercase'}
                               fontWeight={'bold'}
                               fontSize={'10px'}
                               textAlign={'center'}
                               color={Color.DARK_GREY}
                        />
                        <Label text={item.address}
                               textTransform={'capitalize'}
                               fontWeight={'bold'}
                               fontSize={'13px'}
                               textAlign={'center'}
                               color={Color.LIGHT_BLUE}
                        />
                    </InfoColumn>
                </InfoRow>
            </Info>
            <Button
                label={t('book-course-reserve-button')}
                color={Color.BLUE}
                icon={Icons.COMMONS.CALENDAR}
                onClick={ () => goToPage('corso', item.courseId + '/' + item.variationId)}
            />
        </StyledItem>
    )
}

export default CarouselItem