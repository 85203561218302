export const FE_BASE_URL: string = (process.env.REACT_APP_FE_BASE_URL as string) || '';
export const BE_BASE_URL: string = (process.env.REACT_APP_BE_BASE_URL as string) || '';
export const COOKIE_DOMAIN: string = (process.env.REACT_APP_COOKIE_DOMAIN as string) || '';
export const COOKIE_MAX_AGE: string = (process.env.REACT_APP_COOKIE_MAX_AGE as string) || '0';
export const BE_COMPANY_ENDPOINT: string = '/company/';
export const BE_COURSES_ENDPOINT: string = '/courses/';
export const BE_DATES_ENDPOINT: string = '/dates/';
export const BE_EMPLOYEE_ENDPOINT: string = '/employee/';
export const BE_RESERVATION_ENDPOINT: string = '/reservation/';
export const BE_TEMPLATE_DOWNLOAD_ENDPOINT: string = '/xlsxTemplates/download';
export const BE_IMPORT_EMPLOYEES_ENDPOINT: string = '/employee';
// Attributi per integrazione documentale FORMA1
export const ATTESTATO_DOC_TYPE_ID: number =
  +(process.env.REACT_APP_ATTESTATO_DOC_TYPE_ID as string) || 0;
