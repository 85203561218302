import {FE_BASE_URL} from "./config";
import {Cover} from "../constants/cover";
import {Mode} from "../constants/mode";
import {CourseCarouselItem, CourseDate, DropdownOptions, Employee, ReservedCourse} from "../types";
import {Ambulance, FireExtinguisher, HardHat, Tractor, TriangleAlert, Utensils} from "lucide-react";
import {Color} from "../constants/color";
import {TFunction} from "i18next";
import {Category} from "../constants/categories";
import {Status} from "../constants/status";

export function goToPage(path: string, name : string) {
    window.location.assign(FE_BASE_URL + `/${path}/${name}`);
}

export function getCourseCover(name: string) : string {
    let cover : string = '';
    Cover.forEach(el => {
        if (name.toUpperCase().includes(el.name)) cover = el.icon
    })
    return cover;
}

export function getCourseMode(mode: number) : string {
    return Mode.get(mode)?.label!!
}

export function getDateDuration(dates: CourseDate[]) : number {
    if (dates.length === 1) {
        const day1Start = dates[0].start.toString().split(':')
        const day1End = dates[0].end.toString().split(':')
        const day1StartTime = new Date().setHours(Number(day1Start[0]), Number(day1Start[1]))
        const day1EndTime = new Date().setHours(Number(day1End[0]), Number(day1End[1]))
        const day1duration = day1EndTime.valueOf() - day1StartTime.valueOf();
        let h = Math.floor(day1duration / 1000 / 60 / 60);
        const m = Math.floor((day1duration / 1000 / 60 / 60 - h) * 60)
        if (m !== 0) h = h+m
        return h
    }
    const day1Start = dates[0].start.toString().split(':')
    const day1End = dates[0].end.toString().split(':')
    const day2Start = dates[1].start.toString().split(':')
    const day2End = dates[1].end.toString().split(':')
    const day1StartTime = new Date().setHours(Number(day1Start[0]), Number(day1Start[1]))
    const day1EndTime = new Date().setHours(Number(day1End[0]), Number(day1End[1]))
    const day2StartTime = new Date().setHours(Number(day2Start[0]), Number(day2Start[1]))
    const day2EndTime = new Date().setHours(Number(day2End[0]), Number(day2End[1]))

    const day1duration = day1EndTime.valueOf() - day1StartTime.valueOf();
    const day2duration = day2EndTime.valueOf() - day2StartTime.valueOf();
    const msDuration =  (day1duration + day2duration)
    let h = Math.floor(msDuration / 1000 / 60 / 60);
    const m = Math.floor((msDuration / 1000 / 60 / 60 - h) * 60)
    if (m !== 0) h = h+m
    return h
}

export function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function mapCourseDate(availableDates: Set<CourseDate>): Map<number, CourseDate[]> {
    let mappedDates : Map<number, CourseDate[]> = new Map<number, CourseDate[]>();
    availableDates.forEach(el => {
        let array = mappedDates.get(el.variationId) ?? []
        array.push(el)
        mappedDates.set(el.variationId, array)
    })
    return mappedDates;
}

export const dateOptions : Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};

export const dateOptionDayOnly : Intl.DateTimeFormatOptions = {
    day: 'numeric',
};

export const dateOptionWeekDayOnly : Intl.DateTimeFormatOptions = {
    weekday: 'short',
};

export const dateOptionsMonthOnly : Intl.DateTimeFormatOptions = {
    month: 'long',
};

export const dateOptionsReserved : Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
};

export const dateOptionsShort : Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
};

export const dateOptionsRange : Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
};

export const dateOptionsTable : Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
};

export function getLocale(): string {
    return localStorage.getItem('i18nextLng')!!
}

export function getCourseIcon(courseType: number) {
    let icon = <HardHat color={Color.LIGHT_BLUE} size={48} />;
    switch (courseType) {
        case 1:
            icon = <FireExtinguisher color={Color.LIGHT_BLUE} size={48} />;
            break;
        case 2:
            icon = <Ambulance color={Color.LIGHT_BLUE} size={48} />;
            break;
        case 3:
            icon = <HardHat color={Color.LIGHT_BLUE} size={48} />;
            break;
        case 4:
            icon = <Utensils color={Color.LIGHT_BLUE} size={48} />;
            break;
        case 5:
            icon = <Tractor color={Color.LIGHT_BLUE} size={48} />;
            break;
        case 6:
            icon = <TriangleAlert color={Color.LIGHT_BLUE} size={48} />;
            break;
        default:
            break;
    }
    return icon;
}

export function initHeadquartersFilterBook(courses: CourseCarouselItem[], setHeadquarters: (value: (((prevState: (Set<DropdownOptions> | undefined)) => (Set<DropdownOptions> | undefined)) | Set<DropdownOptions> | undefined)) => void, t: TFunction<"translation", undefined>) {
    let cities : Set<string> = new Set()
    courses.forEach((el: CourseCarouselItem) => cities.add(el.city))
    initHeadquartersFilter(cities, setHeadquarters, t)
}

export function initHeadquartersFilterDates(courses: CourseDate[], setHeadquarters: (value: (((prevState: (Set<DropdownOptions> | undefined)) => (Set<DropdownOptions> | undefined)) | Set<DropdownOptions> | undefined)) => void, t: TFunction<"translation", undefined>) {
    let cities : Set<string> = new Set()
    courses.forEach((el: CourseDate) => cities.add(el.city))
    initHeadquartersFilter(cities, setHeadquarters, t)
}

export function initHeadquartersFilterEmployee(employees: Employee[], setHeadquarters: (value: (((prevState: (Set<DropdownOptions> | undefined)) => (Set<DropdownOptions> | undefined)) | Set<DropdownOptions> | undefined)) => void, t: TFunction<"translation", undefined>) {
    let cities : Set<string> = new Set()
    employees.forEach((el: Employee) => cities.add(el.workplace))
    initHeadquartersFilter(cities, setHeadquarters, t)
}

export function initHeadquartersFilterReserved(courses: ReservedCourse[], setHeadquarters: (value: (((prevState: (Set<DropdownOptions> | undefined)) => (Set<DropdownOptions> | undefined)) | Set<DropdownOptions> | undefined)) => void, t: TFunction<"translation", undefined>) {
    let cities : Set<string> = new Set()
    courses.forEach((el: ReservedCourse) => el.date.forEach(date => cities.add(date.city)))
    initHeadquartersFilter(cities, setHeadquarters, t)
}

function initHeadquartersFilter(cities: Set<string>, setHeadquarters: (value: (((prevState: (Set<DropdownOptions> | undefined)) => (Set<DropdownOptions> | undefined)) | Set<DropdownOptions> | undefined)) => void, t: TFunction<"translation", undefined>) {
    let headQuarterSet: Set<DropdownOptions> = new Set();
    for (let i = 0; i < cities.size; i++) {
        if (i === 0) {
            headQuarterSet.add({value: t('book-course-filter-all'), label: t('book-course-filter-all')});
        }
        headQuarterSet.add({value: Array.from(cities)[i], label: Array.from(cities)[i]});
    }
    setHeadquarters(headQuarterSet);
}

export function initCategoryFilter(courses: CourseCarouselItem[], setCategories: (value: (((prevState: (Set<DropdownOptions> | undefined)) => (Set<DropdownOptions> | undefined)) | Set<DropdownOptions> | undefined)) => void, t: TFunction<"translation", undefined>) {
    let categories : Set<number>= new Set()
    courses.forEach((el: CourseCarouselItem) => categories.add(el.type))
    let categorySet: Set<DropdownOptions> = new Set();
    for (let i = 0; i < categories.size; i++) {
        if (i === 0) {
            categorySet.add({value: '0'.toString(), label: t('book-course-filter-all')});
            continue;
        }
        categorySet.add({value: i.toString(), label: Category.get(i)?.label!!});
    }
    setCategories(categorySet);
}

export function initLectureModes(courses: CourseDate[], setLectureModes: (value: (((prevState: (Set<DropdownOptions> | undefined)) => (Set<DropdownOptions> | undefined)) | Set<DropdownOptions> | undefined)) => void, t: TFunction<"translation", undefined>) {
    let lectureModes : Set<number>= new Set()
    courses.forEach((el: CourseDate) => lectureModes.add(el.mode))
    let lectureModeSet: Set<DropdownOptions> = new Set();
    for (let i = 0; i <= lectureModes.size; i++) {
        if (i === 0) {
            lectureModeSet.add({value: t('book-course-filter-all'), label: t('book-course-filter-all')});
            continue;
        }
        lectureModeSet.add({value: t(Mode.get(i)?.label!!), label: t(Mode.get(i)?.label!!)});
    }
    setLectureModes(lectureModeSet);
}

export function initMonthsFilter(setMonths: (value: (((prevState: (Set<DropdownOptions> | undefined)) => (Set<DropdownOptions> | undefined)) | Set<DropdownOptions> | undefined)) => void, t: TFunction<"translation", undefined>) {
    let monthSet: Set<DropdownOptions> = new Set();
    for (let i = 0; i < 13; i++) {
        if (i === 0) {
            monthSet.add({value: '0', label: t('book-course-filter-all')})
            continue;
        }
        monthSet.add({value: i.toString(), label: t(`months.${i}`)});
    }
    setMonths(monthSet);
}

export function initModesFilter(setModes: (value: (((prevState: (Set<DropdownOptions> | undefined)) => (Set<DropdownOptions> | undefined)) | Set<DropdownOptions> | undefined)) => void, t: TFunction<"translation", undefined>) {
    let modesSet: Set<DropdownOptions> = new Set();
    for (let i = 0; i < Mode.size; i++) {
        if (i === 0) {
            modesSet.add({value: '0', label: t('book-course-filter-all')})
            continue;
        }
        modesSet.add({value: i.toString(), label: Mode.get(i)?.label!!});
    }
    setModes(modesSet);
}

export function initEmployeeStateFilter(setEmployeeStates: (value: (((prevState: (Set<DropdownOptions> | undefined)) => (Set<DropdownOptions> | undefined)) | Set<DropdownOptions> | undefined)) => void, t: TFunction<"translation", undefined>) {
    let stateSet: Set<DropdownOptions> = new Set();
    for (let i = 0; i < Mode.size; i++) {
        if (i === 0) {
            stateSet.add({value: t('book-course-filter-all'), label: t('book-course-filter-all')})
            continue;
        }
        stateSet.add({value: Status.get(i)?.label!!, label: Status.get(i)?.label!!});
    }
    setEmployeeStates(stateSet);
}

export function initJobDescriptionsFilter(employeeList: Employee[], setJobDescriptions: (value: (((prevState: (Set<DropdownOptions> | undefined)) => (Set<DropdownOptions> | undefined)) | Set<DropdownOptions> | undefined)) => void, t: TFunction<"translation", undefined>) {
    let jobDescriptions : Set<string> = new Set()
    employeeList.forEach((el: Employee) => jobDescriptions.add(el.jobDescription))
    let jobDescriptionsSet: Set<DropdownOptions> = new Set();
    for (let i = 0; i < jobDescriptions.size; i++) {
        if (i === 0) {
            jobDescriptionsSet.add({value: t('book-course-filter-all'), label: t('book-course-filter-all')});
        }
        jobDescriptionsSet.add({value: Array.from(jobDescriptions)[i], label: Array.from(jobDescriptions)[i]});
    }
    setJobDescriptions(jobDescriptionsSet);
}