import { Fragment, useState } from "react";
import * as Ariakit from "@ariakit/react";
import { Bell, RefreshCcw, XCircle } from "lucide-react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import CorsiBarChart from "./CorsiBarChart";
import { Color } from "../../constants/color";
import ResetCss from "../ResetCss";

import "./dashboardpage.css";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  row-gap: 2rem;
  column-gap: 1rem;
  justify-content: center;
  padding: 0.5em 1em;
`;

const Card = styled.div`
  border-radius: 8px;
  border: 2px solid var(--blue300);
`;

const Title = styled.h1`
  color: var(--blue300);
  font-size: 40px;
  font-family: "Avenir Next Bold", sans-serif;
  font-weight: bold;
`;

const KpiLayout = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.$color};
`;

const KpiLabel = styled.label<{ $fontSize: string }>`
  font-weight: bold;
  font-size: ${(props) => props.$fontSize};
  text-wrap: nowrap;
`;

const OutlineButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${Color.WHITE};
  background-color: ${Color.BLUE};
  border: 0px;
  padding: 0.3em 1em;
  border-radius: 8px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  padding: 0.2em 1.2em;
`;

const RowLayout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.6em;
  padding: 0.5em 1em;
  text-align: center;
  align-items: center;
`;

const THead = styled(RowLayout)`
  font-weight: bold;
  text-transform: uppercase;
  color: var(--blue300);
`;

const TRow = styled(RowLayout)`
  color: var(--blue500);
  font-weight: bold;
  text-transform: uppercase;
  text-wrap: balance;
  border: 1px solid var(--grey800);
  border-radius: 8px;
  background-color: var(--grey100);
`;

const TRowButton = styled.div`
  background-color: var(--blue500);
  color: var(--white);
  text-transform: uppercase;
  border-radius: 8px;
  padding: 0.3em 0.6em;
  flex-shrink: 0;
`;

const fabbisognoData = {
  inclusi: [
    {
      title: "SICUREZZA LAVORATORI PARTE GENERALE",
      fabbisogno: 15,
      attivi: 10,
      dafare: 5,
    },
    {
      title: "SICUREZZA LAVORATORI PARTE SPECIFICA RISCHIO BASSO",
      fabbisogno: 7,
      attivi: 6,
      dafare: 1,
    },
    {
      title: "SICUREZZA LAVORATORI PARTE SPECIFICA RISCHIO MEDIO",
      fabbisogno: 10,
      attivi: 8,
      dafare: 2,
    },
  ],
  esclusi: [
    {
      title: "AGGIORNAMENTO SICUREZZA LAVORATORI",
    },
    {
      title: "FORMAZIONE AGGIUNTIVA PER PREPOSTO",
    },
  ],
};

const dipendentiData = {
  totali: 26,
  neoassunti: 2,
  sedi: [
    { nome: "Genova", dipendenti: 15 },
    { nome: "Savona", dipendenti: 5 },
    { nome: "Roma", dipendenti: 52 },
  ],
  mansioni: [
    { nome: "Elettricista", dipendenti: 5 },
    { nome: "Idraulico", dipendenti: 8 },
    { nome: "Manovale", dipendenti: 35 },
  ],
};

const kpiData = {
  employees: 15,
  certificates: 26,
  expired: 0,
  expired_1m: 0,
  expired_2m: 0,
  expired_6m: 0,
  not_1m: 0,
  not_2m: 0,
  not_6m: 0,

  new_courses: 0,
};

export default function DashboardPage() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedExpire, setSelectedExpire] = useState(1);

  const expireKey = `expired_${selectedExpire}m` as keyof typeof kpiData;
  const expireValue = kpiData[expireKey] || 0;
  const notKey = `not_${selectedExpire}m` as keyof typeof kpiData;
  const notValue = kpiData[notKey] || 0;

  return (
    <ResetCss>
      <div
        style={{
          margin: "0 auto",
          width: "1200px",
        }}
      >
        <Layout>
          <div
            style={{
              gridColumn: "1/4",
              alignContent: "center",
              backgroundColor: "var(--gray500)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Title>{t("dashboard-title")}</Title>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",
                color: "var(--green500)",
              }}
            >
              <RefreshCcw color={Color.GREEN} />
              <p style={{ fontWeight: "bold" }}>
                {t("dashboard-last-update")}: 12/03/2024 14:35
              </p>
            </div>
          </div>
          <Card
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr",
                gap: "2rem",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "1rem 1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1em",
                  // height: "100%",
                  color: "var(--blue300)",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "1em",
                  }}
                >
                  {/*
                  <ScrollText color={Color.LIGHT_BLUE} width={48} height={48} />
                  */}
                  <p
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bolder",
                      lineHeight: "1em",
                    }}
                  >
                    {t("dashboard-certificates")}
                  </p>
                </div>
                {/*
                <p
                  style={{
                    fontSize: "1em",
                    fontWeight: "bold",
                    textWrap: "balance",
                    lineHeight: "1.1em",
                  }}
                >
                  {t("dashboard-description")}
                </p>
                */}
              </div>
              {/* KPI SCADUTI */}
              <Ariakit.HovercardProvider>
                <Ariakit.HovercardAnchor>
                  <KpiLayout $color="var(--red500)">
                    <a
                      href="/attestati?tab=scadenza&status=3"
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Bell
                        color={Color.RED}
                        width={32}
                        height={32}
                        fill={Color.RED}
                      />
                    </a>
                    <KpiLabel $fontSize="1rem">
                      {t("dashboard-expired")}
                    </KpiLabel>
                    <KpiLabel $fontSize="1.2rem">{kpiData.expired}</KpiLabel>
                  </KpiLayout>
                </Ariakit.HovercardAnchor>
                <Ariakit.Hovercard
                  style={{
                    backgroundColor: "var(--red500)",
                    color: "var(--white)",
                    padding: "1em",
                    borderRadius: "8px",
                  }}
                >
                  <Ariakit.HovercardHeading>
                    {t("dashboard-expired")}
                  </Ariakit.HovercardHeading>
                  <p>Numero attestati scaduti alla data attuale</p>
                </Ariakit.Hovercard>
              </Ariakit.HovercardProvider>
              {/* KPI IN SCADENZA */}
              <Ariakit.HovercardProvider>
                <Ariakit.HovercardAnchor>
                  <KpiLayout $color="var(--yellow500)">
                    <a
                      href="/attestati?tab=scadenza&status=2"
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Bell
                        color={Color.YELLOW}
                        width={32}
                        height={32}
                        fill={Color.YELLOW}
                      />
                    </a>
                    <div
                      style={{
                        display: "flex",
                        gap: ".1em",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <KpiLabel $fontSize="1rem" htmlFor="select_expire">
                        {t("dashboard-expiring")}
                      </KpiLabel>
                      <select
                        id="select_expire"
                        style={{
                          border: "none",
                          color: "var(--yellow500)",
                          fontWeight: "bold",
                          outline: "none",
                        }}
                        onChange={(e) =>
                          setSelectedExpire(parseInt(e.target.value))
                        }
                      >
                        <option value="1">1M</option>
                        <option value="2">2M</option>
                        <option value="6">6M</option>
                      </select>
                    </div>
                    <KpiLabel $fontSize="1rem">
                      {`${expireValue}${notValue ? (" di cui " + notValue + " non prenotati") : ""}`}
                    </KpiLabel>
                  </KpiLayout>
                </Ariakit.HovercardAnchor>
                <Ariakit.Hovercard
                  style={{
                    backgroundColor: "var(--yellow500)",
                    color: "var(--white)",
                    padding: "1em",
                    borderRadius: "8px",
                  }}
                >
                  <Ariakit.HovercardHeading>{`In scadenza ${selectedExpire}M`}</Ariakit.HovercardHeading>
                  <p>{`Numero attestati in scadenza entro ${
                    selectedExpire === 1 ? "1 mese" : selectedExpire + " mesi"
                  } e per i quali non è ancora stata pianificata il rinnovo`}</p>
                </Ariakit.Hovercard>
              </Ariakit.HovercardProvider>
              {/* KPI VALIDI */}
              <Ariakit.HovercardProvider>
                <Ariakit.HovercardAnchor>
                  <KpiLayout $color="var(--green500)">
                    <a
                      href="/attestati?tab=scadenza&status=1"
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Bell
                        color={Color.GREEN}
                        width={32}
                        height={32}
                        fill={Color.GREEN}
                      />
                    </a>
                    <KpiLabel $fontSize="1rem">{t("dashboard-valid")}</KpiLabel>
                    <KpiLabel $fontSize="1.2rem">
                      {kpiData.certificates - kpiData.expired}
                    </KpiLabel>
                  </KpiLayout>
                </Ariakit.HovercardAnchor>
                <Ariakit.Hovercard
                  style={{
                    backgroundColor: "var(--green500)",
                    color: "var(--white)",
                    padding: "1em",
                    borderRadius: "8px",
                  }}
                >
                  <Ariakit.HovercardHeading>
                    {t("dashboard-valid")}
                  </Ariakit.HovercardHeading>
                  <p>
                    Numero attestati in validità. Il numero comprende gli
                    attestati validi e in scadenza, ma non ancora scaduti
                  </p>
                </Ariakit.Hovercard>
              </Ariakit.HovercardProvider>
              {/* KPI NUOVI CORSI */}
              <Ariakit.HovercardProvider>
                <Ariakit.HovercardAnchor>
                  <KpiLayout $color="var(--blue500)">
                    <a
                      href="/attestati?tab=scadenza&status=4"
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Bell
                        color={Color.BLUE}
                        width={32}
                        height={32}
                        fill={Color.BLUE}
                      />
                    </a>
                    <KpiLabel $fontSize="1rem">{t("dashboard-new")}</KpiLabel>
                    <KpiLabel $fontSize="1.2rem">
                      {kpiData.new_courses}
                    </KpiLabel>
                  </KpiLayout>
                </Ariakit.HovercardAnchor>
                <Ariakit.Hovercard
                  style={{
                    backgroundColor: "var(--blue500)",
                    color: "var(--white)",
                    padding: "1em",
                    borderRadius: "8px",
                  }}
                >
                  <Ariakit.HovercardHeading>
                    {t("dashboard-new")}
                  </Ariakit.HovercardHeading>
                  <p>
                    Numero di corsi da svolgere per la prima volta e per i quali
                    non si hanno attestati.
                  </p>
                  <p>
                    Si ricorda che la criticità di questa tipologia è
                    equivalente ad attestati scaduti.
                  </p>
                </Ariakit.Hovercard>
              </Ariakit.HovercardProvider>
            </div>
          </Card>
          <Card>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
                padding: "1rem 1rem",
                height: "100%",
                width: "100%",
              }}
            >
              {/*
              <ShieldCheck
                size={48}
                color={Color.GREEN}
                style={{
                  flexShrink: 0,
                }}
              />
              */}
              <p
                style={{
                  fontSize: "2rem",
                  color: Color.LIGHT_BLUE,
                  fontWeight: "bolder",
                  lineHeight: "1em",
                }}
              >
                {t("dashboard-training-needs")}
              </p>
              <OutlineButton onClick={() => setOpen(true)}>
                Visualizza
              </OutlineButton>
              {/* KPI DIPENDENTI */}
              {/*
              <KpiLayout $color="var(--blue500)">
                <User color={Color.BLUE} width={40} height={40} />
                <KpiLabel $fontSize="1rem">{t("dashboard-employees")}</KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.employees}</KpiLabel>
              </KpiLayout>
              */}
              {/* KPI ATTESTATI */}
              {/*
              <KpiLayout $color="var(--yellow500)">
                <ScrollText color={Color.YELLOW} width={40} height={40} />
                <KpiLabel $fontSize="1rem">
                  {t("dashboard-certificates")}
                </KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.certificates}</KpiLabel>
              </KpiLayout>
              */}
              {/* KPI CRITICITA */}
              {/*
              <KpiLayout $color="var(--red500)">
                <TriangleAlert color={Color.RED} width={40} height={40} />
                <KpiLabel $fontSize="1rem">{t("dashboard-criticism")}</KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.expired}</KpiLabel>
              </KpiLayout>
              */}
            </div>
          </Card>

          <Card style={{ gridRow: "3/4", gridColumn: "1/3" }}>
            <DipendentiCard />
          </Card>

          {/* GRAFICI CORSI */}
          <Card style={{ gridRow: "4/5", gridColumn: "1/3" }}>
            <CorsiBarChart chartWidth="100%" chartHeight="300px" />
          </Card>
          {/* GRAFICI FORMAZIONE */}
          {/*
          <Card style={{ gridRow: "4/5", gridColumn: "1/3" }}>
            <FormazioneBarChart chartWidth="100%" chartHeight="300px" />
          </Card>
          */}
          {/* FABBISOGNO FORMATIVO */}
          {/*
          <CardShadowed style={{ gridColumn: "2/3", gridRow: "3/5" }}>
            <FabbisognoFormativo />
          </CardShadowed>
          */}
        </Layout>
      </div>

      <Ariakit.Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="dialog"
      >
        <Ariakit.DialogHeading className="heading">
          Fabbisogno formativo calcolato
          <Ariakit.DialogDismiss
            style={{
              position: "absolute",
              backgroundColor: "transparent",
              border: "none",
              top: ".5em",
              right: ".5em",
              cursor: "pointer",
            }}
          >
            <XCircle size={24} color={Color.GREEN} />
          </Ariakit.DialogDismiss>
        </Ariakit.DialogHeading>
        <DialogContent>
          {/* TITOLI */}
          <THead>
            <p></p>
            <p>Fabbisogno formativo</p>
            <p>Attivi</p>
            <p>Da fare</p>
            <p></p>
          </THead>
          {/* ROWS TABLE */}
          {fabbisognoData.inclusi.map((row) => (
            <TRow key={row.title}>
              <p
                style={{
                  textAlign: "left",
                }}
              >
                {row.title}
              </p>
              <p>{row.fabbisogno}</p>
              <p>{row.attivi}</p>
              <p>{row.dafare}</p>
              <TRowButton>DETTAGLI</TRowButton>
            </TRow>
          ))}
          <div>
            <p
              style={{
                color: "var(--blue500)",
                fontSize: "1.3em",
                fontWeight: "bold",
                textAlign: "center",
                margin: "0",
                padding: ".5rem 0",
              }}
            >
              Corsi non inclusi nel tuo pacchetto
            </p>
            <ul
              style={{
                color: "var(--blue500)",
                fontWeight: "bold",
                fontSize: "1em",
                marginBlockEnd: "0",
                marginBlockStart: "0",
                marginLeft: "18em",
                marginBottom: "2em",
              }}
            >
              {fabbisognoData.esclusi.map((row) => (
                <li
                  style={{
                    margin: "0",
                  }}
                  key={row.title}
                >
                  {row.title}
                </li>
              ))}
            </ul>
          </div>
        </DialogContent>
      </Ariakit.Dialog>
    </ResetCss>
  );
}

const DipLayout = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 2rem;
`;

const DipTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  font-size: 1.2rem;
  column-gap: 2rem;
  row-gap: 2rem;
`;

const DipTitle = styled.div`
  color: var(--blue300);
  font-size: 2rem;
  font-weight: bolder;
  line-height: 1em;
`;

const DipTableTitle = styled.p`
  padding: 0.2rem 0rem;
  font-weight: bold;
`;

const DipTableList = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 0.2rem;
  padding: 0.2rem 1rem;
  & > p[x-data="title"] {
    font-weight: bold;
  }
  & > p[x-data="number"] {
    font-variant-numeric: lining-nums;
    text-align: right;
  }
`;

function DipendentiCard() {
  return (
    <DipLayout>
      <DipTable>
        {/* Prima riga titolo piu totale e neoassunti */}
        <DipTitle>Dipendenti</DipTitle>
        <DipTableList>
          <p x-data="title">Numero totale</p>
          <p x-data="number">{dipendentiData.totali}</p>
        </DipTableList>
        <p></p>
        <DipTableList>
          <p x-data="title">Numero neoassunti</p>
          <p x-data="number">{dipendentiData.neoassunti}</p>
        </DipTableList>
        {/* Seconda riga */}
        {/* Elenco dipendenti per sede */}
        <div
          style={{
            gridColumn: "1/3",
            gridRow: "2/3",
            backgroundColor: "var(--grey100)",
            opacity: "0.15",
            borderRadius: "6px",
          }}
        />
        <DipTableTitle
          style={{
            gridColumn: "1",
            gridRow: "2",
            textAlign: "right",
          }}
        >
          SEDE
        </DipTableTitle>
        <DipTableList
          style={{
            gridColumn: "2",
            gridRow: "2",
          }}
        >
          {dipendentiData.sedi.map((sede) => (
            <Fragment key={sede.nome}>
              <p x-data="title">{sede.nome}</p>
              <p x-data="number">{sede.dipendenti}</p>
            </Fragment>
          ))}
        </DipTableList>
        {/* Elenco dipendenti per mansione */}
        <div
          style={{
            gridColumn: "3/6",
            gridRow: "2/3",
            backgroundColor: "var(--grey100)",
            opacity: "0.15",
            borderRadius: "6px",
          }}
        />
        <DipTableTitle
          style={{
            gridColumn: "3",
            gridRow: "2",
            textAlign: "right",
          }}
        >
          MANSIONE
        </DipTableTitle>
        <DipTableList
          style={{
            gridColumn: "4",
            gridRow: "2",
          }}
        >
          {dipendentiData.mansioni.map((m) => (
            <Fragment key={m.nome}>
              <p x-data="title">{m.nome}</p>
              <p x-data="number">{m.dipendenti}</p>
            </Fragment>
          ))}
        </DipTableList>
      </DipTable>
    </DipLayout>
  );
}
