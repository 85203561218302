import {useParams} from "react-router-dom";
import {CourseDate, CourseDateFilters, CoursePage, DropdownOptions} from "../../types";
import CourseDateCarousel from "../../components/course/date-carousel";
import styled from "styled-components";
import Title from "../../components/title";
import {useTranslation} from "react-i18next";
import {Color} from "../../constants/color";
import Label from "../../components/label";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {participantsContext, selectedParticipant} from "../../resources/mock";
import {getCourseById} from "../../features/course/utils";
import "headless-react-datepicker/dist/styles.css";
import * as Ariakit from "@ariakit/react";
import {Select, SelectItem, SelectLabel, SelectProvider} from "../../components/form/select";
import {BreakpointsQuery} from "../../constants/device";
import {initHeadquartersFilterDates, initLectureModes} from "../../utils/utils";
import {Mode} from "../../constants/mode";
import Calendar from "../../components/form/calendar";

const PageWrapper = styled.div`
    margin-top: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
`;

const DropdownSection = styled.div`
    display: flex;
    column-gap: 0.8rem;
    background-color: ${Color.BLUE};
    padding: 1rem;
    border-radius: 1rem;
    align-items: flex-end;
    @media ${BreakpointsQuery.tablet} {
        justify-content: center;
        row-gap: 1rem;
    }
`;



const Course = () => {
    const { t } = useTranslation();
    let { courseId, dateId } = useParams();
    const participantsSelected = useContext(selectedParticipant);
    const [course, setCourse] = useState<CoursePage>()
    const [courseDates, setCourseDates] = useState<Set<CourseDate>>(course?.availableDates!!)
    const [headquarters, setHeadquarters] = useState<Set<DropdownOptions>>()
    const [lectureModes, setLectureModes] = useState<Set<DropdownOptions>>()

    const participantList = useContext(participantsContext)
    if (dateId !== undefined && dateId.length > 0 && dateId.includes(',')) {
      let array = dateId.split(',');
      array.forEach(el => {
        let sel = (participantList.at(Number(el))!!.lastName + ' ' + participantList.at(Number(el))!!.firstName).toLowerCase()
        participantsSelected.add(sel)
      })
    }


  const initPage = useCallback(() => {
    if (courseId !== undefined) {
      getCourseById(courseId.toUpperCase())!!.then(response => {
        setCourse(response)
        setCourseDates(response.availableDates)
        initHeadquartersFilterDates(Array.from(response.availableDates), setHeadquarters, t)
        initLectureModes(Array.from(response.availableDates), setLectureModes, t)
      });
    }
  }, [courseId, t]);

  useEffect(() => {
    initPage()
  }, [initPage]);

  const [filters, setFilters] = React.useState<CourseDateFilters>({
    lectureMode: t('book-course-filter-all'),
    headquarter: t('book-course-filter-all'),
    startDate: new Date(),
    endDate: undefined,
  });

  const filterDate = useCallback(() => {
    let filtered = Array.from(course?.availableDates ?? []);
    if (filters.lectureMode !== t('book-course-filter-all')) {
      filtered = filtered.filter(el =>
          t(Mode.get(el.mode)!!.label) === filters.lectureMode
      )
    }
    if (filters.headquarter !== t('book-course-filter-all')) {
      filtered = filtered.filter(el =>
          el.city.toLowerCase().includes(filters.headquarter.toLowerCase())
      )
    }
    if (filters.endDate !== undefined) {
      filtered = filtered.filter(el =>
          new Date(el.date) <= filters.endDate!! && new Date(el.date) >= filters.startDate
      )
    }
    setCourseDates(new Set(filtered))
  }, [course?.availableDates, filters.endDate, filters.headquarter, filters.lectureMode, filters.startDate, t]);

    const selectLectureMode = Ariakit.useSelectStore({
      value: filters.lectureMode.toString(),
      setValue: (value) => {
        setFilters((prev) => ({ ...prev, lectureMode: value}))
      },
    });

    const selectHeadquarter = Ariakit.useSelectStore({
      value: filters.headquarter.toString(),
      setValue: (value) => {
        setFilters((prev) => ({ ...prev, headquarter: value}))
      },
    });

    useEffect(() => {
      filterDate()
    }, [filterDate, filters]);

        return course && (
            <PageWrapper>
                <Title text={t('book-course-title')}/>
                <Label text={course.name}
                       fontSize={'20px'}
                       textTransform={'uppercase'}
                       color={Color.GREEN}
                       textAlign={'center'}
                       fontWeight={'bold'}
                />
                <DropdownSection>
                  { lectureModes && <SelectProvider store={selectLectureMode}>
                      <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.1rem",
                          }}
                      >
                          <SelectLabel
                              style={{
                                paddingLeft: "0.5rem",
                                fontSize: "16px",
                                color: Color.WHITE,
                                textTransform: "uppercase",
                                fontFamily: "Avenir Next Bold, sans-serif",
                              }}
                          >
                            {t('book-course-lecture-mode')}
                          </SelectLabel>
                          <Select
                              style={{
                                minWidth: "200px",
                                borderStyle: "solid",
                                borderWidth: "2px",
                                borderRadius: "20px",
                                borderColor: Color.LIGHT_BLUE,
                                padding: "0",
                                paddingLeft: "14px",
                                height: "30px",
                                fontFamily: "Avenir Next Regular, sans-serif",
                              }}
                              renderValue={(value) => Array.from(lectureModes).find(mode => mode.value === value)?.label}
                          >
                            {Object.entries(Array.from(lectureModes)).map(([key, value]) => (
                                <SelectItem
                                    key={`mode_${key}`}
                                    value={value.label}
                                    style={{
                                      justifyContent: "center"
                                    }}
                                >
                                  {t(value.label)}
                                </SelectItem>
                            ))}
                          </Select>
                      </div>
                  </SelectProvider>}
                  { headquarters && <SelectProvider store={selectHeadquarter}>
                      <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.1rem",
                          }}
                      >
                          <SelectLabel
                              style={{
                                paddingLeft: "0.5rem",
                                fontSize: "16px",
                                color: Color.WHITE,
                                textTransform: "uppercase",
                                fontFamily: "Avenir Next Bold, sans-serif",
                              }}
                          >
                            {t('book-course-search-headquarter')}
                          </SelectLabel>
                          <Select
                              style={{
                                minWidth: "200px",
                                borderStyle: "solid",
                                borderWidth: "2px",
                                borderRadius: "20px",
                                borderColor: Color.LIGHT_BLUE,
                                padding: "0",
                                paddingLeft: "14px",
                                height: "30px",
                                fontFamily: "Avenir Next Regular, sans-serif",
                              }}
                              renderValue={(value) => Array.from(headquarters).find(city => city.value === value)?.label}
                          >
                            {Object.entries(Array.from(headquarters)).map(([key, value]) => (
                                <SelectItem
                                    key={`city_${key}`}
                                    value={value.label}
                                    style={{
                                      justifyContent: "center"
                                    }}
                                >
                                  {value.label}
                                </SelectItem>
                            ))}
                          </Select>
                      </div>
                  </SelectProvider>}
                  <Calendar
                      setDateFilters={(rangeDate) => {
                        setFilters((prev) => ({
                          ...prev,
                          startDate: rangeDate[0],
                          endDate: rangeDate[1],
                        }))
                      }}
                  />
                </DropdownSection>
                <CourseDateCarousel dates={courseDates}/>
            </PageWrapper>
        );
};

export default Course