import axios, {AxiosError} from "axios";
import {BE_BASE_URL, BE_RESERVATION_ENDPOINT} from "../../utils/config";
import {ReservedCourse} from "../../types";

export function reserveCourseDate(variationId: number, participants: string[]) {
  try {
    const axiosResponse = axios.post(BE_BASE_URL + BE_RESERVATION_ENDPOINT + 'date', {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      },
      companyId: localStorage.getItem('companyId'),
      variationId: variationId,
      participants: participants
    })
    return axiosResponse.then(response => response.status)
  } catch (e: any) {
    const err = e as AxiosError;
    if (err.response && err.response.data && err.message) {
      console.log("error if", err);
    } else {
      console.log("error else", err);
      // TODO Handle generic error
    }
  }
}

export function getReserveCourseDateByCompanyId(variationId: number) {
  try {
    const axiosResponse = axios.get<Set<string>>(BE_BASE_URL + BE_RESERVATION_ENDPOINT + 'getParticipantsByCompanyAndDate', {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      },
      params: {
        companyId: localStorage.getItem('companyId'),
        variationId: variationId
      }
    })
    return axiosResponse.then(response => response.data)
  } catch (e: any) {
    const err = e as AxiosError;
    if (err.response && err.response.data && err.message) {
      console.log("error if", err);
    } else {
      console.log("error else", err);
      // TODO Handle generic error
    }
  }
}

export function getAllReserveCourseDateByCompanyId() {
  try {
    const axiosResponse = axios.get<ReservedCourse[]>(BE_BASE_URL + BE_RESERVATION_ENDPOINT + 'getAllByCompany', {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      },
      params: {
        companyId: localStorage.getItem('companyId'),
      }
    })
    return axiosResponse.then(response => response.data)
  } catch (e: any) {
    const err = e as AxiosError;
    if (err.response && err.response.data && err.message) {
      console.log("error if", err);
    } else {
      console.log("error else", err);
      // TODO Handle generic error
    }
  }
}