import axios, {AxiosError} from "axios";
import {UserPageResponse} from "../../types";
import {BE_BASE_URL, BE_COMPANY_ENDPOINT} from "../../utils/config";

export function getCompanyInfoByUser(){
  try {
    const axiosResponse = axios.get<UserPageResponse>(BE_BASE_URL + BE_COMPANY_ENDPOINT + 'getByUser', {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      }
    })
    return axiosResponse.then(response => response.data)
  } catch (e: any) {
    const err = e as AxiosError;
    if (err.response && err.response.data && err.message) {
      console.log("error if", err);
    } else {
      console.log("error else", err);
      // TODO Handle generic error
    }
  }
}

export function setCompanyInfoByUser(newUserData : UserPageResponse){
  try {
    const axiosResponse = axios.post<UserPageResponse>(BE_BASE_URL + BE_COMPANY_ENDPOINT + 'editByUser', {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      },
      adminFirstName: newUserData.adminFirstName,
      adminLastName: newUserData.adminLastName,
      adminPhone: newUserData.adminPhone,
      adminEmail: newUserData.adminEmail,
      businessName: newUserData.businessName,
      vatNumber: newUserData.vatNumber,
      cem: newUserData.cem,
      tdEmployees: newUserData.tdEmployees,
      tiEmployees: newUserData.tiEmployees,
      totEmployees: newUserData.totEmployees,
      companyInfo: newUserData.companyInfo,
      headquarters: Array.from(newUserData.headquarters)
    })
    return axiosResponse.then(response => response)
  } catch (e: any) {
    const err = e as AxiosError;
    if (err.response && err.response.data && err.message) {
      console.log("error if", err);
    } else {
      console.log("error else", err);
    }
  }
}