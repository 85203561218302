import axios, {AxiosError} from "axios";
import {Employee} from "../../types";
import {BE_BASE_URL, BE_EMPLOYEE_ENDPOINT} from "../../utils/config";

export function getEmployeeByCompanyId(){
  try {
    const axiosResponse = axios.get<Employee[]>(BE_BASE_URL + BE_EMPLOYEE_ENDPOINT + 'byCompanyId', {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      },
      params: {
        companyId: localStorage.getItem('companyId')
        ,
      }
    })
    return axiosResponse.then(response => response.data)
  } catch (e: any) {
    const err = e as AxiosError;
    if (err.response && err.response.data && err.message) {
      console.log("error if", err);
    } else {
      console.log("error else", err);
      // TODO Handle generic error
    }
  }
}