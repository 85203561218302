import styled from "styled-components";
import {Color} from "../../constants/color";
import {ButtonProps} from "../../types";

const StyledButton = styled.button<{
  $color: string;
  $textColor: string;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.4rem;
    position: relative;
    margin-top: auto;
    width: max-content;
    height: 32px;
    border-radius: 18px;
    border-style: hidden;
    background-color: ${$color => $color.$color ?? Color.LIGHT_BLUE};
    color: ${$color => $color.$textColor ?? Color.WHITE};;
    padding: 0.4rem 1rem;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Avenir Next", sans-serif;
    cursor:pointer;
`;

const ButtonIcon = styled.img <{
    $show: boolean;
}>`
    display: ${props => props.$show ? 'unset' : 'none'};
    width: 1.2rem;
`;

function Button ( {icon, label, color, onClick, textColor} : ButtonProps ) {
    return (
        <StyledButton type={'button'}
                      $color={color!!}
                      $textColor={textColor!!}
                      onClick={onClick}>
            <ButtonIcon alt={label} src={icon} $show={icon !== undefined}/>
            {label}
        </StyledButton>
    );
}

export default Button