import React, { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";

import * as Ariakit from "@ariakit/react";
import { Bell, CalendarDays, FileDown, Search } from "lucide-react";
import {
  Select,
  SelectItem,
  SelectLabel,
  SelectProvider,
} from "../form/select";
import { goToPage } from "../../utils/utils";
import { ATTESTATO_DOC_TYPE_ID, BE_BASE_URL } from "../../utils/config";
import axios from "axios";
import { useAuth } from "../../provider/authProvider";
import { add, format, isAfter, isBefore, parse } from "date-fns";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 4rem;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 2rem;
  @media ${BreakpointsQuery.tablet} {
    justify-content: center;
    gap: 1rem;
  }
`;

const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  & label {
    margin-left: 0.5rem;
    font-family: Avenir Next, sans-serif;
    font-weight: bold;
    color: ${Color.LIGHT_BLUE};
  }
  & input {
    border: 1px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    padding: 0.2rem 1rem;
    width: 100%;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.LIGHT_BLUE};
  color: ${Color.WHITE};
  top: 0;
  right: 0;
  bottom: 0;
  width: 2.5rem;
  border-radius: 0 1rem 1rem 0;
`;

const Card = styled.div`
  border-radius: 8px;
  border: 2px solid var(--blue300);
`;

const KpiLayout = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.$color};
`;

const KpiLabel = styled.label<{ $fontSize: string }>`
  font-weight: bold;
  font-size: ${(props) => props.$fontSize};
`;

//Definisco mappa sedi
const sedeAttestati: { [key: string]: string } = {
  "": "TUTTE",
  GENOVA: "GENOVA",
  SAVONA: "SAVONA",
  IMPERIA: "IMPERIA",
};

// Definisco una mappa con lo stato attestati:
// 1 = valido
// 2 = in scadenza
// 3 = scaduto
const statoAttestati: { [key: number]: string } = {
  0: "TUTTI",
  1: "VALIDO",
  2: "IN SCADENZA",
  3: "SCADUTO",
  4: "NUOVI",
};

const kpiData = {
  employees: 15,
  certificates: 26,
  expired: 0,
  expired_1m: 0,
  expired_2m: 0,
  expired_6m: 0,
  not_1m: 0,
  not_2m: 0,
  not_6m: 0,

  new_courses: 0,
};

type filtersType = {
  corso: string;
  sede: string;
  stato: number;
};

export default function RicercaPerScadenza() {
  const { t } = useTranslation();
  const { token } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const filterStatus = urlParams.get("status") || 0;

  const [attestati, setAttestati] = React.useState([]);

  const [filters, setFilters] = React.useState<filtersType>({
    corso: "",
    sede: "",
    stato: +filterStatus,
  });

  const debouncedFilters = useDebounce(filters, 300);
  const [selectedExpire, setSelectedExpire] = useState(1);

  const expireKey = `expired_${selectedExpire}m` as keyof typeof kpiData;
  const expireValue = kpiData[expireKey] || 0;
  const notKey = `not_${selectedExpire}m` as keyof typeof kpiData;
  const notValue = kpiData[notKey] || 0;

  useEffect(() => {
    async function fetchAttestati(filters: filtersType) {
      const url = `${BE_BASE_URL}/certificates`;
      const params = new URLSearchParams();
      params.append("page", "1");
      params.append("size", "1000");
      params.append(
        "order",
        "cognome_discente,nome_discente,cf_discente,inizio_corso:desc"
      );
      params.append("dTId", ATTESTATO_DOC_TYPE_ID.toString());
      if (debouncedFilters.corso) {
        params.append("titolo_corso", filters.corso);
      }
      if (debouncedFilters.sede) {
        params.append("sede_azienda", filters.sede);
      }
      const result = await axios.get(`${url}?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.data && result.data.ok) {
        const corsi = result.data.content.data;
        // console.log("Attestati ricerca per scadenza: ", corsi);
        const attestati = corsi.map((c: any) => {
          const metadata = JSON.parse(c.metadata);
          const fineCorso = parse(
            metadata.fine_corso,
            "yyyy-MM-dd",
            new Date()
          );
          const scadenza = add(fineCorso, { years: metadata.durata });
          const inScadenza = add(scadenza, { months: -6 });
          const dataPrenotazione = metadata.data_prenotazione
            ? parse(
                metadata.data_prenotazione,
                "yyyy-MM-dd HH:mm:ss.SSS",
                new Date()
              )
            : "";
          return {
            id: c.id,
            fileName: c.fileName,
            name: metadata.titolo_corso,
            code: metadata.codice_corso,
            discente: `${metadata.cognome_discente} ${metadata.nome_discente}`,
            cf: metadata.cf_discente,
            headquarter: metadata.sede_azienda,
            date_exp: format(scadenza, "dd/MM/yyyy"),
            status_expire: isBefore(scadenza, new Date())
              ? 3
              : isAfter(inScadenza, new Date())
              ? 1
              : 2,
            date_renew: dataPrenotazione
              ? format(dataPrenotazione, "dd/MM/yyyy")
              : "",
            status: dataPrenotazione ? 2 : 1,
          };
        });
        // Filtro stato attestato lo possiamo fare solo in pagina per ora, il documentale non supporta filtri su campi calcolati
        if (debouncedFilters.stato) {
          return attestati.filter(
            (a: any) => a.status_expire === debouncedFilters.stato
          );
        }
        return attestati;
      }
    }

    fetchAttestati(debouncedFilters).then((attestati) => {
      setAttestati(attestati);
    });
  }, [debouncedFilters, token]);

  const selectSede = Ariakit.useSelectStore({
    value: filters.sede,
    setValue: (value) => {
      // console.log("Selected value", value);
      setFilters((prev) => ({ ...prev, sede: value }));
    },
  });

  const selectStato = Ariakit.useSelectStore({
    value: `${filters.stato}`,
    setValue: (value) => {
      // console.log("Selected value", value);
      setFilters((prev) => ({ ...prev, stato: +value }));
    },
  });

  return (
    <Layout>
      <SearchContainer>
        <SearchInput>
          <label htmlFor="searchCorso">CORSO</label>
          <div
            style={{
              position: "relative",
              minWidth: "600px",
            }}
          >
            <input
              name="searchCorso"
              type="text"
              placeholder="Ricerca corso"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, corso: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <SelectProvider store={selectSede}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.1rem",
            }}
          >
            <SelectLabel
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              SEDE AZIENDALE
            </SelectLabel>
            <Select
              style={{
                minWidth: "200px",
              }}
              renderValue={(value) => sedeAttestati[`${value}`]}
            >
              {Object.entries(sedeAttestati).map(([key, value]) => (
                <SelectItem key={`sedeAttestato_${key}`} value={key}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </div>
        </SelectProvider>
        <SelectProvider store={selectStato}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.1rem",
            }}
          >
            <SelectLabel
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              STATO
            </SelectLabel>
            <Select
              style={{
                minWidth: "200px",
              }}
              renderValue={(value) => statoAttestati[+value]}
            >
              {Object.entries(statoAttestati).map(([key, value]) => (
                <SelectItem key={`statoAttestato_${key}`} value={key}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </div>
        </SelectProvider>
      </SearchContainer>

      <Card
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minWidth: "67rem",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr",
            gap: "2rem",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem 1rem",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              // height: "100%",
              color: "var(--blue300)",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                gap: "1em",
              }}
            >
              {/*
                  <ScrollText color={Color.LIGHT_BLUE} width={48} height={48} />
                  */}
              <p
                style={{
                  fontSize: "2rem",
                  fontWeight: "bolder",
                  lineHeight: "1em",
                }}
              >
                {t("dashboard-certificates")}
              </p>
            </div>
            {/*
                <p
                  style={{
                    fontSize: "1em",
                    fontWeight: "bold",
                    textWrap: "balance",
                    lineHeight: "1.1em",
                  }}
                >
                  {t("dashboard-description")}
                </p>
                */}
          </div>
          {/* KPI SCADUTI */}
          <Ariakit.HovercardProvider>
            <Ariakit.HovercardAnchor>
              <KpiLayout $color="var(--red500)">
                <a
                  href="/attestati?tab=scadenza&status=3"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Bell
                    color={Color.RED}
                    width={32}
                    height={32}
                    fill={Color.RED}
                  />
                </a>
                <KpiLabel $fontSize="1rem">{t("dashboard-expired")}</KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.expired}</KpiLabel>
              </KpiLayout>
            </Ariakit.HovercardAnchor>
            <Ariakit.Hovercard
              style={{
                backgroundColor: "var(--red500)",
                color: "var(--white)",
                padding: "1em",
                borderRadius: "8px",
                zIndex: "10",
              }}
            >
              <Ariakit.HovercardHeading>
                {t("dashboard-expired")}
              </Ariakit.HovercardHeading>
              <p>Numero attestati scaduti alla data attuale</p>
            </Ariakit.Hovercard>
          </Ariakit.HovercardProvider>
          {/* KPI IN SCADENZA */}
          <Ariakit.HovercardProvider>
            <Ariakit.HovercardAnchor>
              <KpiLayout $color="var(--yellow500)">
                <a
                  href="/attestati?tab=scadenza&status=2"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Bell
                    color={Color.YELLOW}
                    width={32}
                    height={32}
                    fill={Color.YELLOW}
                  />
                </a>
                <div
                  style={{
                    display: "flex",
                    gap: ".1em",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <KpiLabel $fontSize="1rem" htmlFor="select_expire">
                    {t("dashboard-expiring")}
                  </KpiLabel>
                  <select
                    id="select_expire"
                    style={{
                      border: "none",
                      color: "var(--yellow500)",
                      fontWeight: "bold",
                      outline: "none",
                    }}
                    onChange={(e) =>
                      setSelectedExpire(parseInt(e.target.value))
                    }
                  >
                    <option value="1">1M</option>
                    <option value="2">2M</option>
                    <option value="6">6M</option>
                  </select>
                </div>
                <KpiLabel $fontSize="1rem">
                  {`${expireValue}${
                    notValue ? " di cui " + notValue + " non prenotati" : ""
                  }`}
                </KpiLabel>
              </KpiLayout>
            </Ariakit.HovercardAnchor>
            <Ariakit.Hovercard
              style={{
                backgroundColor: "var(--yellow500)",
                color: "var(--white)",
                padding: "1em",
                borderRadius: "8px",
                zIndex: "10",
              }}
            >
              <Ariakit.HovercardHeading>{`In scadenza ${selectedExpire}M`}</Ariakit.HovercardHeading>
              <p>{`Numero attestati in scadenza entro ${
                selectedExpire === 1 ? "1 mese" : selectedExpire + " mesi"
              } e per i quali non è ancora stata pianificata il rinnovo`}</p>
            </Ariakit.Hovercard>
          </Ariakit.HovercardProvider>
          {/* KPI VALIDI */}
          <Ariakit.HovercardProvider>
            <Ariakit.HovercardAnchor>
              <KpiLayout $color="var(--green500)">
                <a
                  href="/attestati?tab=scadenza&status=1"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Bell
                    color={Color.GREEN}
                    width={32}
                    height={32}
                    fill={Color.GREEN}
                  />
                </a>
                <KpiLabel $fontSize="1rem">{t("dashboard-valid")}</KpiLabel>
                <KpiLabel $fontSize="1.2rem">
                  {kpiData.certificates - kpiData.expired}
                </KpiLabel>
              </KpiLayout>
            </Ariakit.HovercardAnchor>
            <Ariakit.Hovercard
              style={{
                backgroundColor: "var(--green500)",
                color: "var(--white)",
                padding: "1em",
                borderRadius: "8px",
                zIndex: "10",
              }}
            >
              <Ariakit.HovercardHeading>
                {t("dashboard-valid")}
              </Ariakit.HovercardHeading>
              <p>
                Numero attestati in validità. Il numero comprende gli attestati
                validi e in scadenza, ma non ancora scaduti
              </p>
            </Ariakit.Hovercard>
          </Ariakit.HovercardProvider>
          {/* KPI NUOVI CORSI */}
          <Ariakit.HovercardProvider>
            <Ariakit.HovercardAnchor>
              <KpiLayout $color="var(--blue500)">
                <a
                  href="/attestati?tab=scadenza&status=4"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Bell
                    color={Color.BLUE}
                    width={32}
                    height={32}
                    fill={Color.BLUE}
                  />
                </a>
                <KpiLabel $fontSize="1rem">{t("dashboard-new")}</KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.new_courses}</KpiLabel>
              </KpiLayout>
            </Ariakit.HovercardAnchor>
            <Ariakit.Hovercard
              style={{
                backgroundColor: "var(--blue500)",
                color: "var(--white)",
                padding: "1em",
                borderRadius: "8px",
                zIndex: "10",
              }}
            >
              <Ariakit.HovercardHeading>
                {t("dashboard-new")}
              </Ariakit.HovercardHeading>
              <p>
                Numero di corsi da svolgere per la prima volta e per i quali non
                si hanno attestati.
              </p>
              <p>
                Si ricorda che la criticità di questa tipologia è equivalente ad
                attestati scaduti.
              </p>
            </Ariakit.Hovercard>
          </Ariakit.HovercardProvider>
        </div>
      </Card>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          width: "78rem",
        }}
      >
        <TableCertificates attestati={attestati} />
      </div>
    </Layout>
  );
}

const TableAttestati = styled.div`
  display: grid;
  grid-template-columns: auto 300px 150px 150px 150px 100px 60px;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: bold;
`;

const TableAttestatiHeader = styled(TableAttestati)`
  text-transform: uppercase;
  color: ${Color.LIGHT_BLUE};
`;

const TableAttestatiRow = styled(TableAttestati)`
  border: 1px solid ${Color.GREY};
  border-radius: 16px;
  text-transform: uppercase;
  color: ${Color.DARK_GREY};
  background-color: ${Color.LIGHT_GREY};
  min-height: 3.2rem;
`;

function TableCertificates({ attestati }: { attestati: any[] }) {
  const { token } = useAuth();

  const [filterdAttestati, setFilteredAttestati] = React.useState(attestati);
  const [searchPartecipante, setSearchPartecipante] = React.useState("");

  const debauncedSearchPartecipante = useDebounce(searchPartecipante, 300);

  useEffect(() => {
    if (debauncedSearchPartecipante) {
      setFilteredAttestati(
        attestati.filter((a) =>
          a.discente
            .toLowerCase()
            .includes(debauncedSearchPartecipante.toLowerCase())
        )
      );
    } else {
      setFilteredAttestati(attestati);
    }
  }, [debauncedSearchPartecipante, attestati]);

  function renderScadenza(attestato: any) {
    const color =
      attestato.status_expire === 1
        ? Color.GREEN
        : attestato.status_expire === 2
        ? Color.YELLOW
        : attestato.status_expire === 3
        ? Color.RED
        : "transparent";
    const label =
      attestato.status_expire === 1
        ? "VALIDO FINO AL "
        : attestato.status_expire === 2
        ? "SCADE IL "
        : attestato.status_expire === 3
        ? "SCADUTO IL "
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
        }}
      >
        <Bell color={color} fill={color} width={24} height={24} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ".2rem",
            color,
          }}
        >
          <p>{label}</p>
          <p>{attestato.date_exp}</p>
        </div>
      </div>
    );
  }

  function renderInfo(attestato: any) {
    const label =
      attestato.status === 1
        ? ""
        : attestato.status === 2
        ? "PRENOTATO IL " + attestato.date_renew
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
          color: Color.BLUE,
        }}
      >
        {attestato.status === 2 ? (
          <CalendarDays color={Color.BLUE} width={24} height={24} />
        ) : null}
        <p>{label}</p>
      </div>
    );
  }

  async function handleDownloadAttestato(id: number, fileName: string) {
    const url = `${BE_BASE_URL}/certificates/${id}`;
    const response = await axios({
      url,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);
    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  return (
    <>
      <SearchInput style={{ width: "80%" }}>
        <div
          style={{
            position: "relative",
          }}
        >
          <input
            name="searchPartecipantiCorso"
            type="text"
            placeholder="Ricerca i partecipanti"
            onChange={(e) => setSearchPartecipante(e.target.value)}
          />
          <SearchButton>
            <Search color={Color.WHITE} size={16} width={16} />
          </SearchButton>
        </div>
      </SearchInput>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: ".5rem",
        }}
      >
        <TableAttestatiHeader>
          <p>Nome e cognome</p>
          <p>Corso</p>
          <p>Sede aziendale</p>
          <p>Stato</p>
          <p>Info</p>
          <p
            style={{
              justifySelf: "center",
            }}
          >
            Attestato
          </p>
        </TableAttestatiHeader>
        {filterdAttestati.map((a) => (
          <TableAttestatiRow key={`attestato_${a.id}`}>
            <p>{a.discente}</p>
            <p>{a.name}</p>
            <p>{a.headquarter}</p>
            <p>{renderScadenza(a)}</p>
            <p>{renderInfo(a)}</p>
            <FileDown
              color={Color.LIGHT_BLUE}
              width={24}
              height={24}
              style={{
                justifySelf: "center",
                cursor: "pointer",
              }}
              onClick={(e) => handleDownloadAttestato(a.id, a.fileName)}
            />
            <p
              style={{
                justifySelf: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  goToPage("corso", `${a.code.toLowerCase()}/-1/${a.cf}`)
                }
              >
                <CalendarDays color={Color.BLUE} />
              </div>
            </p>
          </TableAttestatiRow>
        ))}
      </div>
    </>
  );
}
