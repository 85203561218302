import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import * as Ariakit from "@ariakit/react";
import {
  Select,
  SelectItem,
  SelectProvider,
} from "../form/select"; // Adjust the import path as needed
import { BE_BASE_URL } from "../../utils/config";
import { Color } from "../../constants/color"; // Adjust the import path as needed

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 4rem;
  width: 70rem;
  max-width: 100%;
  margin: 0 auto;
  background-color: var(--white);
  border-radius: 1rem;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  padding: 0 2rem;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-family: "Avenir Next", sans-serif;
    font-weight: bold;
    color: ${Color.LIGHT_BLUE};
  }

  input,
  textarea {
    border: 1px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    width: 100%;
    font-size: 1rem;
  }

  textarea {
    resize: vertical;
    min-height: 100px;
  }
`;

const SubmitButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${Color.WHITE};
  background-color: ${Color.BLUE};
  border: none;
  padding: 0.8em 2em;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-end;

  &:hover {
    background-color: ${Color.DARK_BLUE};
  }
`;

const PrenotaCorsoSede: React.FC = () => {
  const [sedeOptions, setSedeOptions] = useState<Map<string, string>>(new Map());
  const [corsoOptions, setCorsoOptions] = useState<Map<string, string>>(new Map());

  const [sedeLabel, setSedeLabel] = useState<string>(""); // Stores the selected sede label
  const [corsoLabel, setCorsoLabel] = useState<string>(""); // Stores the selected corso label

  const [numeroDipendenti, setNumeroDipendenti] = useState<number | "">("");
  const [descrizione, setDescrizione] = useState("");

  const sedeStore = Ariakit.useSelectStore({
    value: sedeLabel, // Show the label in the dropdown
    setValue: (label) => {
      setSedeLabel(label);
    },
  });

  const corsoStore = Ariakit.useSelectStore({
    value: corsoLabel, // Show the label in the dropdown
    setValue: (label) => {
      setCorsoLabel(label);
    },
  });

  useEffect(() => {
    async function fetchSedi() {
      try {
        const response = await axios.get(`${BE_BASE_URL}/courses/officesInfo`);
        const data: { id: string; office: string }[] = response.data;
        const optionsMap = new Map(data.map(({ id, office }) => [id, office]));
        setSedeOptions(optionsMap);
      } catch (error) {
        console.error("Error fetching offices info:", error);
      }
    }

    async function fetchCorsi() {
      try {
        const response = await axios.get(`${BE_BASE_URL}/courses/packageInfo`);
        const data: { id: string; course: string }[] = response.data;
        const optionsMap = new Map(data.map(({ id, course }) => [id, course]));
        setCorsoOptions(optionsMap);
      } catch (error) {
        console.error("Error fetching course info:", error);
      }
    }

    fetchSedi();
    fetchCorsi();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Simulate form submission with an alert instead of making an actual API call
      alert("Notifica inviata con successo!");
      // Optionally, reset form fields
      setSedeLabel("");
      setCorsoLabel("");
      setNumeroDipendenti("");
      setDescrizione("");
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };
  return (
    <Layout>
      <FormContainer onSubmit={handleSubmit}>
        {/* SEDE Dropdown */}
        <FormField>
          <label htmlFor="sede">SEDE</label>
          <SelectProvider store={sedeStore}>
            <Select id="sede">
              <SelectItem key="" value="" disabled>
                Seleziona una sede
              </SelectItem>
              {Array.from(sedeOptions.entries()).map(([key, value]) => (
                <SelectItem key={`sede_${key}`} value={value}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </SelectProvider>
        </FormField>

        {/* CORSO Dropdown */}
        <FormField>
          <label htmlFor="corso">CORSO</label>
          <SelectProvider store={corsoStore}>
            <Select id="corso">
              <SelectItem key="" value="" disabled>
                Seleziona un corso
              </SelectItem>
              {Array.from(corsoOptions.entries()).map(([key, value]) => (
                <SelectItem key={`corso_${key}`} value={value}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </SelectProvider>
        </FormField>

        {/* NUMERO DIPENDENTI */}
        <FormField>
          <label htmlFor="numeroDipendenti">NUMERO DIPENDENTI</label>
          <input
            type="number"
            id="numeroDipendenti"
            name="numeroDipendenti"
            min="1"
            value={numeroDipendenti}
            onChange={(e) =>
              setNumeroDipendenti(
                e.target.value === "" ? "" : Number(e.target.value)
              )
            }
            required
          />
        </FormField>

        {/* DESCRIZIONE */}
        <FormField>
          <label htmlFor="descrizione">DESCRIZIONE</label>
          <textarea
            id="descrizione"
            name="descrizione"
            value={descrizione}
            onChange={(e) => setDescrizione(e.target.value)}
            required
          />
        </FormField>

        {/* Submit Button */}
        <SubmitButton type="submit">Prenota Corso</SubmitButton>
      </FormContainer>
    </Layout>
  );
};

export default PrenotaCorsoSede;