import axios, {AxiosError} from "axios";
import {BE_BASE_URL, BE_COURSES_ENDPOINT} from "../../utils/config";
import {CourseCarouselItem, CoursePage} from "../../types";

export function getCourseById(courseId: string){
  try {
    const axiosResponse = axios.get<CoursePage>(BE_BASE_URL + BE_COURSES_ENDPOINT + 'courseByUserAndId', {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      },
      params: {
        userEmail: localStorage.getItem('email'),
        courseCode: courseId,
      }
    })
    return axiosResponse.then(response => response.data)
  } catch (e: any) {
    const err = e as AxiosError;
    if (err.response && err.response.data && err.message) {
      console.log("error if", err);
    } else {
      console.log("error else", err);
      // TODO Handle generic error
    }
  }
}

export function getAvailableCourses() {
  try {
    const axiosResponse = axios.get<CourseCarouselItem[]>(BE_BASE_URL + BE_COURSES_ENDPOINT + 'coursesByUser', {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    })
    return axiosResponse.then(response => response.data)
  } catch (e: any) {
    const err = e as AxiosError;
    if (err.response && err.response.data && err.message) {
      console.log("error if", err);
    } else {
      console.log("error else", err);
      // TODO Handle generic error
    }
  }
}