import styled from "styled-components";
import Label from "../../label";
import {useTranslation} from "react-i18next";
import {Color} from "../../../constants/color";
import {BreakpointsQuery} from "../../../constants/device";
import {Icons} from "../../../constants/icons";
import {UserPageResponse} from "../../../types";

interface Props {
  companyData: UserPageResponse | undefined;
}

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-around;
    column-gap: 100px;
    border-radius: 20px;
    box-shadow: 0 0 20px 10px ${Color.SHADOW};
    height: 140px;
    width: 1240px;
    @media ${BreakpointsQuery.tablet} {
        width: 960px;
        column-gap: unset;
    }
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
`;

const ProfileHeader = ({companyData}: Props) => {
  const { t } = useTranslation();
  return (
      <HeaderContainer>
        <DescriptionContainer>
          <Label text={t('user-summary-label')}
                 fontSize={'30px'}
                 fontWeight={'bold'}
          />
          <Label text={t('user-summary-description')}
                 fontSize={'14px'}
                 width={'340px'}
          />
        </DescriptionContainer>
        <img src={Icons.COMMONS.USER}
             alt={'user avatar'}
             width={'80px'}
        />
        <SummaryContainer>
          <Label text={companyData ? companyData.adminFirstName + ' ' + companyData.adminLastName : ''}
                 fontSize={'30px'}
                 fontWeight={'bold'}
                 textAlign={'right'}
                 textTransform={'capitalize'}
          />
          <Label text={companyData ? companyData.adminEmail : ''}
                 fontSize={'14px'}
                 fontWeight={'bold'}
                 width={'340px'}
                 textAlign={'right'}
                 textTransform={'lowercase'}
          />
          <Label text={companyData ? companyData.businessName : ''}
                 fontSize={'14px'}
                 fontWeight={'bold'}
                 width={'340px'}
                 textAlign={'right'}
                 textTransform={'capitalize'}
          />
        </SummaryContainer>
      </HeaderContainer>
  );
}

export default ProfileHeader