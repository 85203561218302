import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Location, NavLink, useLocation} from "react-router-dom";
import {BreakpointsQuery} from "../../constants/device";
import {useTranslation} from "react-i18next";
import {Color} from "../../constants/color";
import {Icons} from "../../constants/icons";
import Label from "../label";
import {useAuth} from "../../provider/authProvider";
import {ChevronDown} from "lucide-react";

const NavShadow = styled.div`
    box-shadow: 0 6px 3px -3px ${Color.SHADOW};
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    position: sticky;
    top: 0;
    background-color: ${Color.WHITE};
    z-index: 100;
`;

const NavContainer = styled.div`
    display: flex;
    justify-content: center;
    z-index: 100;
    height: 8.5rem;
    column-gap: 20px;
    width: 1240px;
    text-align: center;
    @media ${BreakpointsQuery.tablet} {
        column-gap: 12px;
    }
`;

const NavLogoContainer = styled(NavLink)`
    display: inline-flex;
    @media ${BreakpointsQuery.tablet} {

    }
`;

const NavLogo = styled.img`

`;

const NavButton = styled(NavLink)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: ${Color.LIGHT_BLUE};
    height: 100px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bolder;
    font-family: "Avenir Next Bold", sans-serif;
    font-size: 13px;
`;

const NavUserMenuButton = styled(NavLink)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${Color.LIGHT_BLUE};
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bolder;
    font-family: "Avenir Next Bold", sans-serif;
    font-size: 10px;
    text-wrap: pretty;
    z-index: 3;
    height: 16px;
    &:hover {
        background-color: ${Color.LIGHT_BLUE};
        color: ${Color.WHITE};
    }
`;

const NavUser = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-decoration: none;
    position: relative;
    top: 40px;

`;

const ImageContainer = styled.div`
    z-index: 3;
    position: relative !important;
    bottom: 0 !important;
    height: 60px;

    div {
        position: relative;
        bottom: 44px;
        right: 0.5px;
        z-index: 3;
    }
`

const NavUserImage = styled.img`
    width: 60px;
    z-index: 3;
    background-color: ${Color.WHITE};
    clip-path: circle(46% at 50% 50%);
    @media ${BreakpointsQuery.tablet} {

    }
`;

const NavMenu = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 50px;
    position: relative !important;
    bottom: 33px !important;
    border-color: ${Color.LIGHT_BLUE};
    border-style: solid;
    border-width: 3px;
    padding-top: 40px;
    background-color: ${Color.WHITE};
    padding-bottom: 20px;
`;

const NavBar = () => {
    const {t} = useTranslation();
    const {token, setToken} = useAuth();
    const [showMenu, setShowMenu] = useState(false)
    const [currentPage, setCurrentPage] = useState(useLocation())

    const useLocationChange = (action: { (currentPage: any): void; (arg0: Location<any>): void; }) => {
        const location = useLocation()
        React.useEffect(() => { action(location) }, [action, location])
    }

    useLocationChange((nextPage => {
        setCurrentPage(nextPage)
    }))

    useEffect(() => {
        setShowMenu(false);
    }, [token, currentPage]);

    return (
        <NavShadow>
            <NavContainer>
                <NavLogoContainer to={'/'}>
                    <NavLogo alt={'logo'} src={Icons.LOGO.NAVBAR}/>
                </NavLogoContainer>
                <NavButton to={'/dashboard'}>{t('menu-dashboard')}</NavButton>
                <NavButton to={'/corsi-prenotati'}>{t('menu-reserved-courses')}</NavButton>
                <NavButton to={'/attestati'}>{t('menu-certificate-management')}</NavButton>
                <NavButton to={'/anagrafica-dipendenti'}>{t('menu-employee-registry')}</NavButton>
                <NavButton to={'/prenota-un-corso'}>{t('menu-book-course')}</NavButton>
                { token &&
                    <NavUser>
                        <ImageContainer>
                            <NavUserImage src={Icons.COMMONS.USER}/>
                            <Label text={localStorage.getItem('avatarLabel') ?? ''}
                                   color={Color.LIGHT_BLUE}
                                   fontSize={'18px'}
                                   fontWeight={'bolder'}
                            />
                            <ChevronDown color={Color.LIGHT_BLUE}
                                         style={{
                                             position: 'relative',
                                             bottom: '52px',
                                         }}
                                         fill={Color.LIGHT_BLUE}
                                         onClick={() => setShowMenu(!showMenu)}
                            />
                        </ImageContainer>
                        {showMenu && <NavMenu>
                            <NavUserMenuButton to={'/user'}>{t('menu-user-page')}</NavUserMenuButton>
                            <NavUserMenuButton to={''}>{t('menu-setup')}</NavUserMenuButton>
                            <NavUserMenuButton to={''} onClick={ () => {
                                setToken('logout');
                                !token && window.location.replace('/login')
                            }}>{t('navbar-logout')}</NavUserMenuButton>
                        </NavMenu>}
                    </NavUser>
                }
            </NavContainer>
        </NavShadow>
    );
};

export default NavBar